import React, { useState, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import {
    Box,
} from "@mui/material";
import { OrderPage } from "./OrderPage";

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';

const gridStyle = { minHeight: 550 }

export const OrdersList = (props) => {

  function formatDate(inputDate) {
    // Parse the date
    const date = new Date(inputDate);

    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();

    // Return formatted string
    return `${day}/${month}/${year}`;
}

    const [selectedItem, setSelectedItem] = useState(null);
    const [activateRowOnFocus, setActivateRowOnFocus] = useState(true);

    if (props.orders.result.length === 0) return (<div>No orders found</div>);
    // console.log("orders", props.orders.result);
    const rows = props.orders.result.map((it, index) => {
      return {
        id: index,
        no: it.orderNumber,
        documentNumber: it.id,
        documentType: it.documentType,
        selltoCustomerNo: it.sellToCustomerNumber,
        selltoCustomerName: it.sellToCustomerName,
        postingDate: it.postingDate,
        vehicleRegistrationNumber: it.vehicleRegistrationNumber,
        location: it.location,
        locationCode: it.locationCode,
        fitterName: it.fitterName,
        fitterNo: it.fitterNumber,
        serviceType: it.serviceType,
        mileage: it.mileage,
        docketNo: it.docketNumber,
        reTorque: it.reTorque,
        salesPersonCode: it.salesPersonCode,
        tyreCheck: it.tyreCheck,
        postedSalesInvoiceLines: it.postedSalesInvoiceLines,
      };
    });
  
    const columns = [
      {
        name: 'id',
        header: 'ID',
        defaultVisible: false,
        defaultWidth: 1,
        type: 'number',
      },
      {
        name: 'no',
        header: 'No',
        defaultFlex: 0.25,
        render: ({ value, data }) => (
          <div
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              setSelectedItem(data);
              props.setShowItem(true);
            }}
          >
            {value}
          </div>
        ),
      },
      {
        name: 'postingDate',
        header: 'Posting',
        defaultFlex: 0.25,
        render: ({ value }) => <div>{formatDate(value)}</div>,
      },
      {
        name: 'vehicleRegistrationNumber',
        header: 'Reg',
        defaultFlex: 0.25,
        render: ({ value }) => <div>{value}</div>,
      },
      {
        name: 'location',
        header: 'Location',
        defaultFlex: 0.25,
        render: ({ value }) => <div>{value}</div>,
      },
      {
        name: 'locationCode',
        header: 'Warehouse',
        defaultFlex: 0.25,
        render: ({ value }) => <div>{value}</div>,
      },
      {
        name: 'fitterName',
        header: 'Fitter',
        defaultFlex: 0.25,
        render: ({ value }) => <div>{value}</div>,
      },
    ];
  
    const renderRowContextMenu = (menuProps, { rowProps, cellProps }) => {
        menuProps.autoDismiss = true
        menuProps.items = [
        ]
    }

    return (
      <Row id="shop-items" className="g-3">
        <Box sx={{ height: '100%', width: '100%' }}>
          {!props.showItem ? (
            <ReactDataGrid
              idProperty="id"
              style={gridStyle}
              activateRowOnFocus={activateRowOnFocus}
              columns={columns}
              dataSource={rows}
              enableSelection={true}
              renderRowContextMenu={renderRowContextMenu}
              showHoverRows={true}
            />
          ) : (
            <OrderPage onBack={() => props.setShowItem(false)} item={selectedItem} />
          )}
          
        </Box>
      </Row>
    );
  };