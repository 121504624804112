import React, {useState, useCallback, useEffect, forwardRef, useImperativeHandle} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import {ShoppingCartService} from "../../services/shoppingCartService";
import ProductsService from "../../services/productsService";
import { Alert, Button, Card, Col, Row, Spinner } from "react-bootstrap";

/**
 * Renders shopping cart
 * @param props 
 */
export const ShopCart = forwardRef((props, ref) => {

    const productService = ProductsService();
    const cartService = ShoppingCartService();

    const [isLoading, setIsLoading] = useState(false);
    const [orderPlaced, setOrderPlaced] = useState("");

    const [shoppingCart, setShoppingCart] = useState({items: []});
    const [images, setImages] = useState([]);

    const [show, setShow] = useState(false);

    useImperativeHandle(ref, () => ({
        async addProductToCart(id, quantity) {
            const activeShoppingCart = await cartService.addItem(id, quantity);
            setShoppingCart(activeShoppingCart);
        }
    }));

    const addItem = async (id) => {
        const activeShoppingCart = await cartService.addItem(id);
        setShoppingCart(activeShoppingCart);
    };

    const clearCart = async () => {
        const activeShoppingCart = await cartService.clearCart();
        
        setShoppingCart(activeShoppingCart);
    };

    const removeFromCart = async (item, quantity) =>{
        if(quantity === "all"){
            setShoppingCart(await cartService.removeTotalItem(item));
        }else{
            setShoppingCart(await cartService.removeItem(item));
        }
    };


    const placeOrder = async () => {
        setIsLoading(true);
        const placeOrderResult = await cartService.placeOrder();
        if(placeOrderResult.success){
            setOrderPlaced(true);
            setShow(true);
            setShoppingCart(placeOrderResult.activeShoppingCart);
        }
        setIsLoading(false);
    };

    useEffect(async () => {
        const activeShoppingCart = await cartService.getShoppingCart();
        setShoppingCart(activeShoppingCart);
    } , [props]);

    useEffect(async () => {
        const imgs = [];
        const imgRequests = shoppingCart.items.reduce((acc, item) => {
            acc.push(productService.getProductImages(item.productId).then(image => imgs[item.productId] = image));
            return acc;
        }, []);
        await Promise.all(imgRequests);
        setImages(imgs);
    }, [shoppingCart]);

    return (
        <Col md={12} id="shop-cart">
        
            <h3>BASKET</h3>
            <Row className="justify-content-between mb-2">
                <Col md={12}>
                    Order Total: {shoppingCart.totalPrice}
                </Col>
            </Row>
            <Row className="justify-content-between mb-4">
                <Col md={6} className="text-start">
                    <Button variant="danger" 
                    disabled={isLoading || shoppingCart.items.length === 0} 
                    onClick={() => clearCart()}>Clear cart</Button>
                </Col>
                <Col md={6} className="text-end">
                    <Button variant="primary"
                        disabled={isLoading || shoppingCart.items.length === 0}
                        onClick={() => placeOrder()}>
                            {isLoading ? <Spinner animation="border" variant="primary" /> : ""}
                            Place Order</Button>
                </Col>
                {orderPlaced != "" ? <div className="col-md-12"> 
                    <Alert show={show} dismissible onClose={() => setOrderPlaced("")} variant="success">Your order was placed successfully</Alert>
                </div> : ""}
            </Row>
            {shoppingCart.items.map(item =>
                <Card key={item.productId} className="shadow-sm rounded-10 mb-1">
                    <Card.Body>
                    <Row className="justify-content-between">
                        <Col md={2} className="p-0">
                            {images[item.productId] ? <img src={`data:image/jpeg;base64,${images[item.productId]}`} alt="product" className="img-fluid" /> : <Spinner animation="border" variant="primary" />}
                        </Col>
                        <Col md={10}>
                            <Row className="mb-1">
                                <Col className="text-start">{item.description}</Col>
                                <Col md={2}>
                                    <Button variant="outline-danger" size="sm" onClick={() => removeFromCart(item, "all")}>
                                    <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-start d-inline-flex" >
                                    <Button variant="outline-primary" size="sm" onClick={() => removeFromCart(item)}><FontAwesomeIcon icon={faMinus} /></Button>
                                    <span className="text-center align-middle pt-1" style={{width: 40 }}>{item.quantity}</span>
                                    <Button variant="outline-primary" size="sm" onClick={() => addItem(item.productId)}><FontAwesomeIcon icon={faPlus} /></Button>
                                </Col>
                                <Col md="auto" className="align-self-end">
                                    <strong>{item.totalPrice}</strong>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    </Card.Body>
                </Card>
            )}
        </Col>
    );
});