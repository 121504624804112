import React, { useState, useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../services/authConfig";
import { callMsGraph } from "../../services/graph";
import CustomerService  from "../../services/customerService";
import  ProfileTop  from "./ProfileTop";

import '../../styles/header.scss';

export const TopBar = (props) => {
    
      const isAuthenticated = useIsAuthenticated();
      const [userName, setUserName] = useState(null);
      const { instance, accounts } = useMsal();
      const [graphData, setGraphData] = useState(null);
      const [customerData, setCustomerData] = useState(null);

      if (isAuthenticated && !graphData) {
        instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0]
        })
          .then((response) => {
            setUserName(accounts[0].name);
            callMsGraph(response.accessToken)
              .then(resp => setGraphData(resp));
          });
      }
  
      useEffect(() => {
          if (accounts.length > 0) {
              RequestProfileData();
          };
          const customerService = CustomerService();
          customerService.getCustomer().then((response) => {
              //console.log(response);
              setCustomerData(response);
              //console.log(customerData);
          });
  
      }, [accounts]);
  
      function RequestProfileData() {
          // Silently acquires an access token which is then attached to a request for MS Graph data
          instance.acquireTokenSilent({
              ...loginRequest,
              account: accounts[0]
          }).then((response) => {
              callMsGraph(response.accessToken).then(resp => setGraphData(resp));
          });
      }

      return (
        <>
        {isAuthenticated && graphData && customerData &&
        <ProfileTop userName={graphData.givenName + " " + graphData.surname} 
                    customerName={customerData.name} 
                    balanceLCY={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(customerData.balanceLCY)} 
                    balanceDueLCY={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(customerData.balanceDueLCY)}
                    creditLimitLCY={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(customerData.creditLimitLCY)}
                    outstandingOrdersLCY={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(customerData.outstandingOrdersLCY)}/>
        }
        </>
      )
    }