import React, { useState, useEffect } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { PageLayout } from "./components/landing/PageLayout";
import { Card, Col, Row, Button, Container } from "react-bootstrap";
import { Shop } from '../src/components/shop/Shop';
import { SignInButton } from "./components/common/SignInButton";
import { RequestInterceptor } from "./components/common/RequestInterceptor";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { ShopBasket } from "./components/shop/ShopBasket";
import { ShopItemPage } from "./components/shop/ShopItemPage";
import { ProfileContent } from "./components/profile/ProfileContent";
import { Orders } from "./components/orders/Orders";
import { PendingOrders } from "./components/pendingOrders/PendingOrders";
import { Invoices } from "./components/invoices/Invoices";



/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = (props) => {

    let isAuthenticated = useIsAuthenticated();
    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        if (isAuthenticated && !location.pathname.includes("/shop") 
        && !location.pathname.includes("/basket") 
        && !location.pathname.includes("/item")
        && !location.pathname.includes("/profile")
        && !location.pathname.includes("/orders")
        && !location.pathname.includes("/pendingorders")
        && !location.pathname.includes("/invoices")
        ) {
            navigate("/shop", { replace: true });
        }
    }, [isAuthenticated]);


    function ErrorFallback({ error, resetErrorBoundary }) {
        return (
            <div role="alert">
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <Button onClick={resetErrorBoundary} variant="primary" >Try again</Button>
            </div>
        )
    }

    const [hasError, setHasError] = React.useState(false)
    return (
        <div className="App">

            <AuthenticatedTemplate>
                <RequestInterceptor>
                    <ErrorBoundary FallbackComponent={ErrorFallback}
                        onReset={() => setHasError(false)}
                        resetKeys={[hasError]}>
                        {
                            hasError ? null :
                                <Routes>
                                    <Route path="shop" element={<Shop />} />
                                    <Route path="basket" element={<ShopBasket />} />
                                    <Route path="item/:id" element={<ShopItemPage />} />
                                    <Route path="profile" element={<ProfileContent />} />
                                    <Route path="pendingorders" element={<PendingOrders />} />
                                    <Route path="orders" element={<Orders />} />
                                    <Route path="invoices" element={<Invoices />} />
                                    {/* <Route path="shop/checkout" element={<ShopCheckout />} /> */}
                                </Routes>
                        }
                    </ErrorBoundary>
                </RequestInterceptor>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>

                <video autoPlay muted loop id="myVideo">
                    <source src="https://www.midlandtyres.ie/images/mts/michelin.mp4" type="video/mp4" />
                </video>
                        
                <div className="Banner">
                    <h3 className="card-title boxed">Please sign-in to see your personalised information.</h3>
                    <SignInButton />
                </div>

                <Container className="fluid">
                    <Row>
                        <Col xs={12} md={6}>
                            <abbr title="Phone">
                                <i className="fa fa-phone"></i>
                            </abbr> 
                            <a href="tel:+35350541102" className="phonelink">+353 (505) 41 102</a>
                        </Col>
                        <Col xs={12} md={6}>
                            <abbr title="Email">
                                <i className="fa fa-envelope"></i>
                            </abbr>
                            <a href="mailto:info@midlandtyres.ie" className="phonelink">info@midlandtyres.ie</a>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={1} md={2}>
                            <img src="assets/images/mts/alcoa-w1.png" alt="" className="center-block imglogos" />
                        </Col>
                        <Col xs={1} md={2}>
                            <img src="assets/images/mts/michelin-w.png" alt="" className="center-block imglogos" />
                        </Col>
                        <Col xs={1} md={2}>
                            <img src="assets/images/mts/goodyear-w.png" alt="" className="center-block imglogos" />
                        </Col>
                        <Col xs={1} md={2}>
                            <img src="assets/images/mts/dunlop-w.png" alt="" className="center-block imglogos" />
                        </Col>
                        <Col xs={1} md={2}>
                        <img src="assets/images/mts/torque.png" alt="" className="center-block imglogos"  />
                        </Col>
                        <Col xs={1} md={2}>
                        <img src="assets/images/mts/bridgestone-w.png" alt="" className="center-block imglogos" />
                        </Col>
                    </Row>
                </Container>

            </UnauthenticatedTemplate>
        </div>
    );
};


export default function App() {
    return (
        <PageLayout>
            <MainContent />
        </PageLayout>
    );
}
