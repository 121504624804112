import http from 'axios';
import base64 from 'base-64';


export const ShoppingCartService = () => {
    let shoppingCart = {
        items: [],
    };

    const getShoppingCart = async () => {
        const url = process.env.REACT_APP_API_URL + '/shop/cart/active';// + '?code='+process.env.REACT_APP_FUNCTION_KEY;
        try {
            
            const res = await http.get(url);
                shoppingCart = res.data;
                return shoppingCart;
        } catch (err) {
            console.log(err);
        }
    };

    const removeTotalItem = async (item) => {
        const url = process.env.REACT_APP_API_URL_POST + `/shop/cart/active/items/${base64.encode(item.productId)}?quantity=all` + '&code='+process.env.REACT_APP_FUNCTION_KEY;

        try {
            const res = await http.delete(url);
            shoppingCart = res.data;
            return shoppingCart;
        } catch (err) {
            console.log(err);
        }
    }

    const removeItem = async (item) => {
        const url = process.env.REACT_APP_API_URL_POST + `/shop/cart/active/items/${base64.encode(item.productId)}` + '?code='+process.env.REACT_APP_FUNCTION_KEY;

        try {
            const res = await http.delete(url);
            shoppingCart = res.data;
            return shoppingCart;
        } catch (err) {
            console.log(err);
        }
    }

    const addItem = async (itemId, quantity) => {
        const url = process.env.REACT_APP_API_URL_POST + '/shop/cart/active/items' + '?code='+process.env.REACT_APP_FUNCTION_KEY;

        try {
            const res = await http.post(url, {id: itemId, quantity});
            shoppingCart = res.data;
            return shoppingCart;
        } catch (err) {
            console.log(err);
        }
    }

    const startCheckout = async () => {
        const url = process.env.REACT_APP_API_URL_POST + '/shop/checkout' + '?code='+process.env.REACT_APP_FUNCTION_KEY;
        try {
            const res = await http.post(url);
            return res.data;
        } catch (err) {
            console.log(err);
            return {
                success: false,
                message: err.message,
            }
        }
    }

    const placeOrder = async (order) => {
        const url = process.env.REACT_APP_API_URL_POST + '/shop/order' + '?code='+process.env.REACT_APP_FUNCTION_KEY;
        try {
            const res = await http.post(url, order);
            return res.data;
        } catch (err) {
            console.log(err);
            return {
                success: false,
                message: err.message,
            }
        }
    }

    const clearCart = async () => {
        const url = process.env.REACT_APP_API_URL_POST + '/shop/cart/active' + '?code='+process.env.REACT_APP_FUNCTION_KEY;
        try {
            const res = await http.delete(url);
            shoppingCart = res.data;
            return shoppingCart;
        } catch (err) {
            console.log(err);
        }
    }

    return { getShoppingCart, removeItem, addItem, startCheckout, placeOrder, clearCart, removeTotalItem };
}

