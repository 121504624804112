import React, {useEffect, useState, useRef } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { Container, Row, Spinner } from "react-bootstrap";

import '../../styles/shop.scss';
import { ShopCategories } from "./ShopCategories";
import { ShopCenter } from "./ShopCenter";
import { UseProductFilter, productsBase } from '../../services/filterService';

import ProductsService from "../../services/productsService";
import { Header } from "../common/Header";
import { ShoppingCartService } from "../../services/shoppingCartService";

import { TopBar } from "../common/TopBar";


/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const Shop = ( props ) => {

    const isAuthenticated = useIsAuthenticated();
    const [cartItemCount, setCartItemCount] = useState(0);
    const shoppingCartService = ShoppingCartService();
  
    useEffect(() => {
      const fetchCartItemCount = async () => {
        const cart = await shoppingCartService.getShoppingCart();
        if (cart && cart.items)
          setCartItemCount(cart.items.length);
      };
      if (isAuthenticated)
      fetchCartItemCount();
    }, [isAuthenticated, shoppingCartService]);
  
    const updateCartItemCount = async () => {
        const cart = await shoppingCartService.getShoppingCart();
        if (cart && cart.items) {
          setCartItemCount(cart.items.length);
          console.log(cart);
          console.log("items", cart.items.length);
        } else {
          console.log("Error getting cart item count");
        }
      };

    const productService = ProductsService();
    const [products, setProducts] = useState(productsBase);
    const [productLoaded, setProductLoaded] = useState(false);
    
    const [search, setSearch] = useState("");
    const [filter, setFilter] = UseProductFilter();

    const [showItem, setShowItem] = useState(false);

    useEffect(() => {
      
        const initState = async () => {
          setProductLoaded(false);
          setProducts(await productService.getProducts(search, filter));
          setProductLoaded(true);
          setShowItem(false);
         
        };
        initState();
      }, []);

    const updateProducts = async (newFilter, newSearch) => {
        setProductLoaded(false);
        setProducts(await productService.getProducts(newSearch, newFilter));
        setProductLoaded(true);
        setShowItem(false);
    }

    const updateFilter = async (filterInput) => {
        console.log("updateFilter",filterInput);
        let a = Object.assign({},filterInput,{page: 1});
        setFilter(a);
        updateProducts(a, search);
        setShowItem(false);
    };

    const onPageSizeChange = async (pageSizeInput) => {
        if(pageSizeInput != filter.pageSize){
            let a = Object.assign({},filter,{pageSize: pageSizeInput, page: 1});
            setFilter(a);
            updateProducts(a, search);
            setShowItem(false);
        }
    };

    const onPageChange = (newPage) => {
        if(filter.page != newPage){
            console.log("onPageChange",newPage, filter.page);
            let a = Object.assign({},filter,{page: newPage});
            setFilter(a);
            updateProducts(a, search);
            setShowItem(false);
        }
    };

    const onSortChange = async (newSort) => {
        if(filter.sort != newSort){
            console.log("onSortChange",newSort);
            let a = Object.assign({},filter,{sort: newSort.sort, sortOrder: newSort.sortOrder, page: 1});
            setFilter(a);
            updateProducts(a, search);
            setShowItem(false);
        }
    };

    const onSearchChange = async (newSearch) => {
        if(search != newSearch){
            console.log("onSearchChange",newSearch);
            let a = Object.assign({},filter,{page: 1});
            setFilter(a);
            setSearch(newSearch);
            updateProducts(filter, newSearch);
            setShowItem(false);
        }
    };

    const [showCategories, setShowCategories] = useState(true);

  const toggleCategories = () => {
    setShowCategories(!showCategories);
  };

    return (
        <>
            <Header cartItemCount={cartItemCount}/>
            <TopBar />
            <Container fluid>
                <Row>
                    
                    
                    {/* <ShopCategories updateFilter={updateFilter} initialFilter={filter} /> */}
                    
                    <ShopCenter updateCartItemCount={updateCartItemCount}
                                onSearchChange={onSearchChange} 
                                search={search} 
                                onPageChange={onPageChange} 
                                loadingProducts={productLoaded} 
                                pages={products.pages} 
                                currentPage={products.page} 
                                products={products} 
                                onPageSizeChange={onPageSizeChange} 
                                onSortChange={onSortChange} 
                                showItem={showItem} 
                                setShowItem={setShowItem} />
                </Row>
            </Container>
        </>
    );
};