import { useState, useEffect } from 'react';

const STORAGE_PRODUCT_KEY = 'productFilter';

export const productsBase = { sort: "Name", sortOrder: "ASC", result: [], pages: [], totalPages: 0, totalCount: 0, page: 1, pageSize: 30 };

export function UseProductFilter() {
  const [filter, setFilter] = useState({
    sort: productsBase.sort,
    sortOrder: productsBase.sortOrder,
    page: productsBase.page,
    pageSize: productsBase.pageSize,
    radius: [],
    wall: [],
    diameter: [],
    categories: [],
    brand: []
  });

  useEffect(() => {
    const storedProductFilter = localStorage.getItem(STORAGE_PRODUCT_KEY);
    if (storedProductFilter) {
      setFilter(JSON.parse(storedProductFilter));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(STORAGE_PRODUCT_KEY, JSON.stringify(filter));
  }, [filter]);

  return [filter, setFilter];
}

