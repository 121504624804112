import React, { useEffect, useState, useRef } from "react";
import '../../styles/shop.scss';
import { Header } from "../common/Header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate, useParams } from "react-router-dom";
import { faMinus, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import {ShoppingCartService} from "../../services/shoppingCartService";
import ProductsService from "../../services/productsService";
import { Alert, Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { CartCheck, CartX } from 'react-bootstrap-icons';
import { TopBar } from "../common/TopBar";



/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const ShopBasket = (props) => {


    const cartService = ShoppingCartService();
    const productService = ProductsService();
    const [cartItemCount, setCartItemCount] = useState(0);
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [orderPlaced, setOrderPlaced] = useState(false);
    const [shoppingCart, setShoppingCart] = useState({ items: [] });
    const [images, setImages] = useState([]);

    async function loadShoppingCart() {
    }

    useEffect(async () => {
        const cart = await cartService.getShoppingCart();
        setShoppingCart(cart);
        setCartItemCount(cart.items.length);
        console.log(cart);
        console.log("items", cart.items.length);
        
        if (cart.items.length > 0) {
            setOrderPlaced(false);
            console.log("ORDER PLACED", orderPlaced)
        }

    }, []);

    useEffect(async () => {
        const imgs = [];
        const imgRequests = shoppingCart.items.reduce((acc, item) => {
            acc.push(productService.getProductImages(item.productId).then(image => imgs[item.productId] = image));
            return acc;
        }, []);
        await Promise.all(imgRequests);
        setImages(imgs);
    }, [shoppingCart]);

    const clearCart = async () => {
        const activeShoppingCart = await cartService.clearCart();
        setCartItemCount(0);
        setShoppingCart(activeShoppingCart);
    };

    const removeFromCart = async (item, quantity) =>{
        if(quantity === "all"){
            setShoppingCart(await cartService.removeTotalItem(item));
        }else{
            setShoppingCart(await cartService.removeItem(item));
        }
    };

    const addItem = async (id) => {
        const activeShoppingCart = await cartService.addItem(id);
        setShoppingCart(activeShoppingCart);
    };

    const placeOrder = async () => {
        setIsLoading(true);
        const placeOrderResult = await cartService.placeOrder();
        if(placeOrderResult.success){
            setOrderPlaced(true);
            setShow(true);
            setShoppingCart(placeOrderResult.activeShoppingCart);
        }
        setIsLoading(false);
    };

    return (
        <>
        <Header cartItemCount={cartItemCount} />
            <TopBar />

        
    <div id="shop-div" className="container-fluid ">
        <div className="row space">
        <Col xs={0} md={1}></Col>
            <Col md={10} id="shop-cart panel">

                <h3 className="text-center">Basket</h3>
                <Row className="justify-content-between mb-2 text-center">
                    <Col md={12}>
                        Order Total: {shoppingCart.totalPrice}
                    </Col>
                </Row>
                <Row className="justify-content-between mb-4">
                <Col md={6} className="text-start">
                        <Button variant="danger"
                        disabled={isLoading || shoppingCart.items.length===0} onClick={() => clearCart()}><CartX /> Clear cart</Button> 
                    </Col>
                    <Col md={6} className="text-end">
                        {(!orderPlaced & shoppingCart.items.length>0) ? 
                        <Button variant="primary"
                            disabled={isLoading || shoppingCart.items.length===0}
                            onClick={() => placeOrder()}>
                            {
                                isLoading ? 
                                <Spinner animation="border" variant="primary" /> : 
                                <><CartCheck />   Place Order</>
                            }
                               
                        </Button> : ""}
                    </Col>
                    {!orderPlaced ? <div className="col-md-12">
                        <Alert show={show} dismissible onClose={() => setOrderPlaced(false)} variant="success">Your order was placed successfully</Alert>
                    </div> : ""}
                </Row>
                {shoppingCart.items.map(item =>
                    <Card key={item.productId} className="shadow-sm rounded-10 mb-1">
                        <Card.Body>
                            <Row className="justify-content-between">
                                <Col md={3} className="p-0">
                                    {
                                        images[item.productId] ? 
                                        <img src={`data:image/jpeg;base64,${images[item.productId]}`} alt="product" className="img-fluid img-shoppingcart" /> : 
                                        <Spinner animation="border" variant="primary" />
                                    }
                                </Col>
                                <Col md={9}>
                                    <Row className="mb-1">
                                        <Col className="text-start">{item.description}</Col>
                                        <Col md={2} className="text-end">
                                            <Button className="text-end" variant="outline-danger" size="sm" onClick={() => removeFromCart(item, "all")}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-start d-inline-flex" >
                                            <Button variant="outline-secondary" size="sm" onClick={() => removeFromCart(item)}><FontAwesomeIcon icon={faMinus} /></Button>
                                            <span className="text-center align-middle pt-1" style={{ width: 40 }}>{item.quantity}</span>
                                            <Button variant="outline-primary" size="sm" onClick={() => addItem(item.productId)}><FontAwesomeIcon icon={faPlus} /></Button>
                                        </Col>
                                        <Col md="auto" className="align-self-end">
                                            <strong>{item.totalPrice}</strong>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                )}
            </Col>
            <Col xs={0} md={1}></Col>
        </div>
    </div>
    </>
    );
}
