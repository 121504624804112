import React, { useEffect, useState } from "react";
import { Form, Col, Row, Container, ButtonGroup, Spinner } from "react-bootstrap";
import { Printer, ArrowReturnLeft } from 'react-bootstrap-icons';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import OrdersService from "../../services/pendingOrdersService";
import "../../styles/Invoices.scss";

export const PendingOrderPage = (props) => {
    console.log("Item coming to OrderPage:", props.item);

    const orderService = OrdersService();
    const gridStyle = { minHeight: 550 };

    const [lines, setLines] = useState();
    const [activateRowOnFocus, setActivateRowOnFocus] = useState(true);
    const [LoadingData, setLoadingData] = useState(true);
    const [showSpinner, setShowSpinner] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const initLines = async () => {
            const lines = await orderService.getPendingOrderLines(props.item.no);
            console.log("Lines coming to OrderPage:", lines);
            setLines(lines);
            setLoadingData(false);
        };
        initLines();

        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Initial check
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchLines = async () => {
            const lines = await orderService.getOrderLines(props.item.no);
            console.log("Lines coming to OrderPage:", lines);
            setLines(lines);
            setLoadingData(false);
        };
        fetchLines();

    }, [props.item.no]);


    const mobileColumns = [
        {
            name: 'description',
            header: 'Description',
            defaultFlex: 1,
            render: ({ value }) => <div className="smallFont">{value}</div>,
        },
        {
            name: 'quantity',
            header: 'Quantity',
            defaultFlex: 0.25,
            render: ({ value }) => <div className="smallFont">{value}</div>,
        },
        {
            name: 'unitPrice',
            header: 'Unit Price',
            defaultFlex: 0.25,
            render: ({ value }) => <div className="smallFont">{value}</div>,
        },
    ];

    const allColumns = [
        // {
        //     name: 'lineNo',
        //     header: 'Line No',
        //     defaultFlex: 0.25,

        // },
        {
            name: 'no',
            header: 'No',
            defaultFlex: 0.25,

        },
        {
            name: 'description',
            header: 'Description',
            defaultFlex: 1,
        },
        {
            name: 'quantity',
            header: 'Quantity',
            defaultFlex: 0.25,
        },
        {
            name: 'unitPrice',
            header: 'Unit Price',
            defaultFlex: 0.25,
        },
        // {
        //     name: 'lineAmount',
        //     header: 'Line Amount',
        //     defaultFlex: 0.25,
        // },
        {
            name: 'position',
            header: 'Position',
            defaultFlex: 0.25,
        },
        {
            name: 'oldPosition',
            header: 'Old Position',
            defaultFlex: 0.25,
        },
        // {
        //     name: 'treadDepth',
        //     header: 'Tread Depth',
        //     defaultFlex: 0.25,
        // },
        {
            name: 'serialNo',
            header: 'Serial No',
            defaultFlex: 0.25,
        },
        {
            name: 'serialNoRetired',
            header: 'Serial No Retired',
            defaultFlex: 0.25,
        },
        {
            name: 'reasonforRemoval',
            header: 'Reason for Removal',
            defaultFlex: 0.25,
        },
    ];

    const columns = screenWidth > 600 ? [...allColumns] : [...mobileColumns];

    let rows = [];
    if (lines) {
        console.log("lines", lines);
        rows = lines.map((it, index) => {
            return {
                id: index,
                no: it.no,
                lineNo: it.lineNo,
                documentNo: it.documentNo,
                description: it.description,
                quantity: it.quantity,
                unitPrice: it.unitPrice,
                lineAmount: it.lineAmount,
                position: it.position,
                oldPosition: it.oldPosition,
                treadDepth: it.treadDepth,
                serialNo: it.serialNo,
                serialNoRetired: it.serialNoRetired,
                reasonforRemoval: it.reasonforRemoval,

            };
        });
    }

    // const printPinkSheet = async () => {
    //     setShowSpinner(true);
    //     const url = `https://fitterportalstorage.blob.core.windows.net/fitterapp-pdf/salesOrder-${orderId}.pdf`;


    //     const file = `salesOrder-${orderId}.pdf`;
    //     await orderService.printPinkSheet(url, file);


    //     setShowSpinner(false);
    // }

    return (
        <>
            <Container fluid>

                <Row >

                <Col xs={0} md={1}></Col>
                    <Col xs={12} md={10}>

                        <Row className="pushdown-10">
                            <Col xs={4} md={2}>
                                <ButtonGroup size="lg" className="mb-2">
                                    <button className="btn btn-secondary text-start" onClick={props.onBack}>
                                        <ArrowReturnLeft /> Back
                                    </button>
                                </ButtonGroup>
                            </Col>
                            <Col xs={4} md={8} className="text-center">
                                {
                                    showSpinner ?
                                    <div className="text-center">
                                        <Spinner className="mt-4" animation="border" variant="primary" />
                                    </div>
                                        : <h4>Pending Sales Order</h4>
                                }
                            </Col>
                            <Col xs={4} md={2} className="text-end">
                                <ButtonGroup size="lg" className="mb-2">
                                    {/* <button className="btn btn-primary text-start" onClick={printPinkSheet}>
                                        <Printer /> Pink Sheet
                                    </button> */}
                                </ButtonGroup>
                            </Col>

                        </Row>
                        <Form className="panel">
                            <Row className="mb-3">
                                <Col xs={4} md={2} className="input-label">
                                    <Form.Label className="text-start">id</Form.Label>
                                </Col>
                                <Col xs={8} md={4} className="input-label">
                                    <Form.Control type="text" value={props.item.documentNumber} readOnly />
                                </Col>

                                <Col xs={4} md={2} className="input-label">
                                    <Form.Label className="text-start">Number</Form.Label>
                                </Col>
                                <Col xs={8} md={4} className="input-label">
                                    <Form.Control type="text" value={props.item.no} readOnly />
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col xs={4} md={2} className="input-label">
                                    <Form.Label className="text-start">Date</Form.Label>
                                </Col>
                                <Col xs={8} md={4} className="input-label">
                                    <Form.Control type="text" value={new Date(props.item.postingDate).toLocaleDateString('en-GB')} readOnly />
                                </Col>

                                <Col xs={4} md={2} className="input-label">
                                    <Form.Label className="text-start">Sell-To No</Form.Label>
                                </Col>
                                <Col xs={8} md={4} className="input-label">
                                    <Form.Control type="text" value={props.item.selltoCustomerNo} readOnly />
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col xs={4} md={2} className="input-label">
                                    <Form.Label className="text-start">Fitter Number</Form.Label>
                                </Col>
                                <Col xs={8} md={4} className="input-label">
                                    <Form.Control type="text" value={props.item.fitterNo} readOnly />
                                </Col>

                                <Col xs={4} md={2} className="input-label">
                                    <Form.Label className="text-start">Sell-To Name</Form.Label>
                                </Col>
                                <Col xs={8} md={4} className="input-label">
                                    <Form.Control type="text" value={props.item.selltoCustomerName} readOnly />
                                </Col>
                            </Row>


                            <Row className="mb-3">
                                <Col xs={4} md={2} className="input-label">
                                    <Form.Label className="text-start">Fitter Name</Form.Label>
                                </Col>
                                <Col xs={8} md={4} className="input-label">
                                    <Form.Control type="text" value={props.item.fitterName} readOnly />
                                </Col>

                                <Col xs={4} md={2} className="input-label">
                                    <Form.Label className="text-start">Location</Form.Label>
                                </Col>
                                <Col xs={8} md={4} className="input-label">
                                    <Form.Control type="text" value={props.item.location} readOnly />
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col xs={4} md={2} className="input-label">
                                    <Form.Label className="text-start">Vehicle Reg</Form.Label>
                                </Col>
                                <Col xs={8} md={4} className="input-label">
                                    <Form.Control type="text" value={props.item.vehicleRegistrationNumber} readOnly />
                                </Col>

                                <Col xs={4} md={2} className="input-label">
                                    <Form.Label className="text-start">Location Code</Form.Label>
                                </Col>
                                <Col xs={8} md={4} className="input-label">
                                    <Form.Control type="text" value={props.item.locationCode} readOnly />
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col xs={4} md={2} className="input-label">
                                    <Form.Label className="text-start">Mileage</Form.Label>
                                </Col>
                                <Col xs={8} md={4} className="input-label">
                                    <Form.Control type="text" value={props.item.mileage} readOnly />
                                </Col>

                                <Col xs={4} md={2} className="input-label">
                                    <Form.Label className="text-start">ReTorque</Form.Label>
                                </Col>
                                <Col xs={4} md={1} className="input-label">
                                    <Form.Check type="text" checked={props.item.reTorque} readOnly />
                                </Col>
                                <Col xs={4} md={2} className="input-label">
                                    <Form.Label className="text-start">Tyre Check</Form.Label>
                                </Col>
                                <Col xs={4} md={1} className="input-label">
                                    <Form.Check type="text" checked={props.item.tyreCheck} readOnly />
                                </Col>
                            </Row>

                        </Form>
                        <Row className="panel mt-3">
                            {
                                //!LoadingData ?

                                <ReactDataGrid
                                    idProperty="id"
                                    style={gridStyle}
                                    activateRowOnFocus={activateRowOnFocus}
                                    columns={columns}
                                    dataSource={rows}
                                    enableSelection={true}
                                    showHoverRows={true}
                                />
                                // : <Spinner className="mt-4" animation="binvoice" variant="primary" />
                            }
                        </Row>
                    </Col>
                    <Col xs={0} md={1}></Col>

                </Row>
            </Container>

        </>
    );
};
