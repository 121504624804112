import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../services/authConfig";
import { callMsGraph } from "../../services/graph";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import { Col, Container, Dropdown, Nav, NavLink, Row, Navbar } from "react-bootstrap";
import { ShoppingCartService } from "../../services/shoppingCartService";

import '../../styles/header.scss';

export const Header = (props) => {

  const isAuthenticated = useIsAuthenticated();

  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const [userName, setUserName] = useState(null);
  // const [cartItemCount, setCartItemCount] = useState(0);

  // const shoppingCartService = ShoppingCartService();

  // useEffect(() => {
  //   const fetchCartItemCount = async () => {
  //     const cart = await shoppingCartService.getShoppingCart();
  //     if (cart && cart.items)
  //       setCartItemCount(cart.items.length);
  //   };

  //   fetchCartItemCount();
  // }, [isAuthenticated, shoppingCartService]);

  if (isAuthenticated && !graphData) {
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    })
      .then((response) => {
        setUserName(accounts[0].name);
        callMsGraph(response.accessToken)
          .then(resp => setGraphData(resp));
      });
  }

  // const updateCartItemCount = async () => {
  //   const cart = await shoppingCartService.getShoppingCart();
  //   if (cart && cart.items)
  //     setCartItemCount(cart.items.length);
  // }


  return (
    <>

      <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
        <Navbar.Brand href="/">
          <img className="logo m-3" src="/logo-sign.png" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav ">
            {
              isAuthenticated ?
                <>
                <Nav className="ml-auto">
                  <Nav.Link href="https://www.midlandtyres.ie">HOME SITE</Nav.Link>
                  <Nav.Link href="/shop">SHOP</Nav.Link>

                  <Nav.Link href="basket">
                    BASKET {props.cartItemCount > 0 && (
                      <span className="badge badge-pill badge-danger">{props.cartItemCount}</span>
                    )}
                  </Nav.Link>

                  <Nav.Link href="/pendingorders">PENDING ORDERS</Nav.Link>
                  <Nav.Link href="/orders">POSTED ORDERS</Nav.Link>
                  <Nav.Link href="/invoices">INVOICES</Nav.Link>
                  </Nav>


                  <Nav className="ms-auto">
                    <Dropdown>
                      <Dropdown.Toggle as={NavLink}>{userName}</Dropdown.Toggle>
                      <Dropdown.Menu className="bg-dark header-userbox">
                        <Dropdown.Item className="link-white header-userbox" href="/profile">PROFILE</Dropdown.Item>
                        <hr className="dropdown-divider" />
                        <SignOutButton />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Nav>
                </>
                : (
                  <SignInButton className="ml-auto" />
                )}
        </Navbar.Collapse>
      </Navbar>

      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, { updateCartItemCount })
      )}
    </>
  );
};

export const updateCartItemCount = Header.updateCartItemCount;
