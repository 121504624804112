import React, { useState, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import {
    Box,
} from "@mui/material";
import { InvoicePage } from "./InvoicePage";

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import "../../styles/Invoices.scss";

const gridStyle = { minHeight: 550 }

export const InvoicesList = (props) => {

  function formatDate(inputDate) {
    // Parse the date
    const date = new Date(inputDate);

    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();

    // Return formatted string
    return `${day}/${month}/${year}`;
}

    const [selectedItem, setSelectedItem] = useState(null);
    const [activateRowOnFocus, setActivateRowOnFocus] = useState(true);

    
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    if (props.invoices.result.length === 0) 
    return (<div className="row space text-center"><h6>No invoices found...</h6></div>);
    const rows = props.invoices.result.map((it, index) => {
      return {
        id: index,
        invoiceId: it.id,
        number: it.number,
        sellToCustomerNumber: it.sellToCustomerNumber,
        sellToCustomerName: it.sellToCustomerName,
        billToCustomerNumber: it.billToCustomerNumber,
        billToCustomerName: it.billToCustomerName,
        postingDate: it.postingDate,
        vehicleRegistrationNumber: it.vehicleRegistrationNumber,
        orderNumber: it.orderNumber,
        amountExclVAT: it.amountExclVAT,
        amountInclVAT: it.amountInclVAT,
        vatAmount: it.vatAmount,
        currencyCode: it.currencyCode,
        location: it.location,
        locationCode: it.locationCode,
        fitterName: it.fitterName,
        fitterNumber: it.fitterNumber,
        serviceType: it.serviceType,
        JobStatus: it.JobStatus,
        mileage: it.mileage,
        docketNumber: it.docketNumber,
        ReTorque: it.ReTorque,
        SalespersonCode: it.SalespersonCode,
        tyreCheck: it.tyreCheck,
        dueDate: it.dueDate,
        paymentTerms: it.paymentTerms,
        postedSalesInvoiceLines: it.postedSalesInvoiceLines,
      };
    });
  
    const allColumns = [
      {
        name: 'id',
        header: 'ID',
        defaultVisible: false,
        defaultWidth: 1,
        type: 'number',
      },
      {
        name: 'number',
        header: 'No',
        defaultFlex: 0.25,
        render: ({ value, data }) => (
          <div
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              setSelectedItem(data);
              props.setShowItem(true);
            }}
          >
            {value}
          </div>
        ),
      },
      {
        name: 'postingDate',
        header: 'Date',
        defaultFlex: 0.25,
        render: ({ value }) => <div>{new Date(value).toLocaleDateString('en-GB')}</div>,
      },
      {
        name: 'vehicleRegistrationNumber',
        header: 'Reg',
        defaultFlex: 0.25,
        render: ({ value }) => <div>{value}</div>,
      },
      {
        name: 'location',
        header: 'Location',
        defaultFlex: 0.25,
        render: ({ value }) => <div>{value}</div>,
      },
      {
        name: 'locationCode',
        header: 'Warehouse',
        defaultFlex: 0.25,
        render: ({ value }) => <div>{value}</div>,
      },
      {
        name: 'fitterName',
        header: 'Fitter',
        defaultFlex: 0.25,
        render: ({ value }) => <div>{value}</div>,
      },
      {
        name: 'amountExclVAT',
        header: 'Subtotal',
        defaultFlex: 0.25,
        render: ({ value }) => <div className="numeric-input">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(value)}</div>,
      },
    ];

    const mobileColumns = [

      {
        name: 'number',
        header: 'No',
        defaultFlex: 0.25,
        render: ({ value, data }) => (
          <div className="smallFont"
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              setSelectedItem(data);
              props.setShowItem(true);
            }}
          >
            {value}
          </div>
        ),
      },
      {
        name: 'postingDate',
        header: 'Date',
        defaultFlex: 0.25,
        render: ({ value }) => <div className="smallFont">{new Date(value).toLocaleDateString('en-GB')}</div>,
      },
      {
        name: 'vehicleRegistrationNumber',
        header: 'Reg',
        defaultFlex: 0.25,
        render: ({ value }) => <div className="smallFont">{value}</div>,
      },
      {
        name: 'amountExclVAT',
        header: 'Subtotal',
        defaultFlex: 0.25,
        render: ({ value }) => <div className="numeric-input smallFont">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(value)}</div>,
      },
    ];

    const columns = screenWidth > 600 ? [...allColumns] : [...mobileColumns];

    const renderRowContextMenu = (menuProps, { rowProps, cellProps }) => {
        menuProps.autoDismiss = true
        menuProps.items = [
        ]
    }

    return (
      <Row id="shop-items" className="g-3">
        <Box sx={{ height: '100%', width: '100%' }}>
          {
          !props.showItem ? 
          (
            rows.length > 0 ? (
            <ReactDataGrid
              idProperty="id"
              style={gridStyle}
              activateRowOnFocus={activateRowOnFocus}
              columns={columns}
              dataSource={rows}
              enableSelection={true}
              renderRowContextMenu={renderRowContextMenu}
              showHoverRows={true}
            />
            ) : (
              <div className="row space text-center"><h6>No invoices found...</h6></div>
            )
          ) 
          : (
            <InvoicePage onBack={() => props.setShowItem(false)} item={selectedItem}/>
          )
          }
          
        </Box>
      </Row>
    );
  };