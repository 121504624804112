import http from 'axios';

const PendingOrdersService = () => {
    const getPendingOrders = async (page, pageSize) => {
        let url = process.env.REACT_APP_API_URL + '/shop/pendingOrders?';// + 'code='+process.env.REACT_APP_FUNCTION_KEY;

        //if(page) {
            url += `&page=${page}&`;
        //}
        if(pageSize) {
            url += `pageSize=${pageSize}&`;
        }
        
        try {
            const res = await http.get(url);
            return res.data;
        } catch (err) {
            console.log(err);
        }
    }

    const getPendingOrderLines = async (orderId) => {
        let url = process.env.REACT_APP_API_URL + `/shop/pendingOrders/${orderId}/lines`;// + '?code='+process.env.REACT_APP_FUNCTION_KEY;

        try {
            const res = await http.get(url);
            return res.data;
        } catch (err) {
            console.log(err);
        }
    }

    const printPinkSheet = async (orderId) => {
        let url =  `https://fitterportalstorage.blob.core.windows.net/fitterapp-pdf/salesOrder-${orderId}.pdf`;
    
        const link = document.createElement('a');
        link.href = url;
        link.download = `salesOrder-${orderId}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return { getPendingOrders, getPendingOrderLines, printPinkSheet };

}

export default PendingOrdersService;