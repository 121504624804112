import React, { useEffect, useState } from "react";
import { Form, Col, Row, Container, ButtonGroup, Spinner, Modal, Button } from "react-bootstrap";
import { Printer, ArrowReturnLeft } from 'react-bootstrap-icons';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import InvoicesService from "../../services/invoicesService";
import OrdersService from "../../services/ordersService";
import "../../styles/Invoices.scss";

export const InvoicePage = (props) => {
    // console.log("Item coming to InvoicePage:", props.item);

    const invoiceService = InvoicesService();
    const orderService = OrdersService();
    const gridStyle = { minHeight: 550 };

    const [lines, setLines] = useState();
    const [activateRowOnFocus, setActivateRowOnFocus] = useState(true);
    const [LoadingData, setLoadingData] = useState(true);
    const [showSpinner, setShowSpinner] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const [showModal, setShowModal] = useState(false);
    const [links, setLinks] = useState([]);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Initial check
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const columns = screenWidth > 600 ? [
        {
            name: 'lineNumber',
            header: 'Line No',
            defaultFlex: 0.25,

        },
        // {
        //     name: 'lineType',
        //     header: 'Type',
        //     defaultFlex: 0.25,
        // },
        {
            name: 'lineObjectNumber',
            header: 'Object No',
            defaultFlex: 0.25,
        },
        {
            name: 'description',
            header: 'Description',
            defaultFlex: 1,
        },
        {
            name: 'unitOfMeasureCode',
            header: 'Unit of Measure Code',
            defaultFlex: 0.25,
        },
        {
            name: 'quantity',
            header: 'Quantity',
            defaultFlex: 0.25,
        },
        {
            name: 'unitPrice',
            header: 'Unit Price',
            defaultFlex: 0.25,
        },
        // {
        //     name: 'lineDiscountAmount',
        //     header: 'Discount Amount',
        //     defaultFlex: 0.25,
        // },
        // {
        //     name: 'lineDiscountPercent',
        //     header: 'Discount Percent',
        //     defaultFlex: 0.25,
        // },
        // {
        //     name: 'lineAmountExclVAT',
        //     header: 'Subtotal',
        //     defaultFlex: 0.25,
        // },
        // {
        //     name: 'vatPercent',
        //     header: 'VAT Percent',
        //     defaultFlex: 0.25,
        // },
        // {
        //     name: 'vatAmount',
        //     header: 'VAT Amount',
        //     defaultFlex: 0.25,
        // },
        // {
        //     name: 'lineAmountInclVAT',
        //     header: 'Line Amount',
        //     defaultFlex: 0.25,
        // },
        // {
        //     name: 'position',
        //     header: 'Position',
        //     defaultFlex: 0.25,
        // },
        // {
        //     name: 'oldPosition',
        //     header: 'Old Position',
        //     defaultFlex: 0.25,
        // },
        // {
        //     name: 'treadDepth',
        //     header: 'Tread Depth',
        //     defaultFlex: 0.25,
        // },
        // {
        //     name: 'serialNumber',
        //     header: 'Serial No',
        //     defaultFlex: 0.25,
        // },
        // {
        //     name: 'retiredSerialNumber',
        //     header: 'Retired Serial No',
        //     defaultFlex: 0.25,
        // },
        // {
        //     name: 'reasonforRemoval',
        //     header: 'Reason for Removal',
        //     defaultFlex: 0.25,
        // },
    ] :
        [
            {
                name: 'description',
                header: 'Description',
                defaultFlex: 1,
                render: ({ value }) => <div className="smallFont">{value}</div>,
            },
            {
                name: 'quantity',
                header: 'Quantity',
                defaultFlex: 0.25,
                render: ({ value }) => <div className="smallFont">{value}</div>,
            },
            {
                name: 'unitPrice',
                header: 'Unit Price',
                defaultFlex: 0.25,
                render: ({ value }) => <div className="smallFont">{value}</div>,
            },

        ];

    //console.log("postedSalesInvoiceLines", props.item.postedSalesInvoiceLines);
    let rows = [];
    if (props.item.postedSalesInvoiceLines) {
        //setLoadingData(false);
        //console.log("postedSalesInvoiceLines 2", props.item.postedSalesInvoiceLines);
        rows = props.item.postedSalesInvoiceLines.map((it, index) => {
            return {
                id: index,
                documentNumber: it.documentNumber,
                lineNumber: it.lineNumber,
                lineType: it.lineType,
                lineObjectNumber: it.lineObjectNumber,
                description: it.description,
                unitOfMeasureCode: it.unitOfMeasureCode,
                quantity: it.quantity,
                unitPrice: it.unitPrice,
                lineDiscountAmount: it.lineDiscountAmount,
                lineDiscountPercent: it.lineDiscountPercent,
                lineAmountExclVAT: it.lineAmountExclVAT,
                vatPercent: it.vatPercent,
                vatAmount: it.vatAmount,
                lineAmountInclVAT: it.lineAmountInclVAT,
                position: it.position,
                oldPosition: it.oldPosition,
                treadDepth: it.treadDepth,
                serialNo: it.serialNo,
                serialNoRetired: it.serialNoRetired,
                reasonforRemoval: it.reasonforRemoval,
            };
        });
    }

    const printPdf = async () => {
        setShowSpinner(true);
        const invoiceId = props.item.invoiceId; // Replace with the correct property name for the invoice ID
        await invoiceService.getInvoicePdf(invoiceId).then(() => {
            setShowSpinner(false);
        });
    }



    const printPinkSheet = async () => {
        console.log("printPinkSheet");
        setShowSpinner(true);

        const url = await invoiceService.getPinkSheetSalesOrderName(props.item.orderNumber);

        //const url = `https://fitterportalstorage.blob.core.windows.net/fitterapp-pdf/salesOrder-${props.item.orderNumber}.pdf`;

        try {
            var items = props.item.postedSalesInvoiceLines.map(it => it.lineObjectNumber).join(',');
            var files = await orderService.getPinkSheetShipmentName(props.item.orderNumber, items);

            if ((files) && (files.length > 0)) {
                const links = await orderService.printPinkSheets(files);
                setLinks(links);
                setShowModal(true);
            } else {
                const file = `salesOrder-${props.item.orderNumber}.pdf`;
                const fileName = invoiceService.getPinkSheetSalesOrderName(props.item.orderNumber);
                await orderService.printPinkSheet(url, fileName);
            }
        } catch (error) {
            // If the HEAD request fails, the file doesn't exist
            console.error('Pink Sheet does not exist:', error);
        } finally {
            setShowSpinner(false);
        }
    }

    return (
        <>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Download Pink Sheets</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {links.map((link, index) => {
                        const fileName = new URL(link).pathname.split('/').pop();
                        return (
                            <p key={index}><a href={link} target="_blank" rel="noopener noreferrer">{fileName}</a></p>
                        );
                    })}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Container fluid>

                <Row >

                    <Col xs={0} md={0}></Col>
                    <Col xs={12} md={12}>

                        <Row className="pushdown-10">
                            <Col xs={4} md={2}>
                                <ButtonGroup size="lg" className="mb-2">
                                    <button className="btn btn-secondary text-start" onClick={props.onBack}>
                                        <ArrowReturnLeft /> Back
                                    </button>
                                </ButtonGroup>
                            </Col>
                            <Col xs={2} md={5}>
                                {
                                    showSpinner ?
                                    <div className="text-center">
                                        <Spinner className="mt-4" animation="border" variant="primary" />
                                    </div>
                                        : null
                                }
                            </Col>
                            <Col xs={6} md={5} className="text-end">
                                <ButtonGroup size="lg" className="mb-2">
                                    <button className="btn btn-primary text-start" onClick={printPdf}>
                                        <Printer /> Invoice
                                    </button>
                                </ButtonGroup>
                                <ButtonGroup size="lg" className="mb-2">
                                    <button className="btn btn-primary text-start" onClick={printPinkSheet}>
                                        <Printer /> POD
                                    </button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                        <Form className="panel">
                            <Row className="mb-3">
                                <Col xs={4} md={2} className="input-label">
                                    <Form.Label className="text-start">Id</Form.Label>
                                </Col>
                                <Col xs={8} md={4} className="input-label">
                                    <Form.Control type="text" value={props.item.invoiceId} readOnly />
                                </Col>

                                <Col xs={4} md={2} className="input-label">
                                    <Form.Label className="text-start">Number</Form.Label>
                                </Col>
                                <Col xs={8} md={4} className="input-label">
                                    <Form.Control type="text" value={props.item.number} readOnly />
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col xs={4} md={2} className="input-label">
                                    <Form.Label className="text-start">Posting Date</Form.Label>
                                </Col>
                                <Col xs={8} md={4} className="input-label">
                                    <Form.Control type="text" value={new Date(props.item.postingDate).toLocaleDateString('en-GB')} readOnly />
                                </Col>

                                <Col xs={4} md={2} className="input-label">
                                    <Form.Label className="text-start">Order Number</Form.Label>
                                </Col>
                                <Col xs={8} md={4} className="input-label">
                                    <Form.Control type="text" value={props.item.orderNumber} readOnly />
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col xs={4} md={2} className="input-label">
                                    <Form.Label className="text-start">Sell-To No</Form.Label>
                                </Col>
                                <Col xs={8} md={4} className="input-label">
                                    <Form.Control type="text" value={props.item.sellToCustomerNumber} readOnly />
                                </Col>

                                <Col xs={4} md={2} className="input-label">
                                    <Form.Label className="text-start">Sell-To Name</Form.Label>
                                </Col>
                                <Col xs={8} md={4} className="input-label">
                                    <Form.Control type="text" value={props.item.sellToCustomerName} readOnly />
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col xs={4} md={2} className="input-label">
                                    <Form.Label className="text-start">Bill-To No</Form.Label>
                                </Col>
                                <Col xs={8} md={4} className="input-label">
                                    <Form.Control type="text" value={props.item.billToCustomerNumber} readOnly />
                                </Col>

                                <Col xs={4} md={2} className="input-label">
                                    <Form.Label className="text-start">Bill-To Name</Form.Label>
                                </Col>
                                <Col xs={8} md={4} className="input-label">
                                    <Form.Control type="text" value={props.item.billToCustomerName} readOnly />
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col xs={4} md={2} className="input-label">
                                    <Form.Label className="text-start">Fitter Number</Form.Label>
                                </Col>
                                <Col xs={8} md={4} className="input-label">
                                    <Form.Control type="text" value={props.item.fitterNumber} readOnly />
                                </Col>

                                <Col xs={4} md={2} className="input-label">
                                    <Form.Label className="text-start">Fitter Name</Form.Label>
                                </Col>
                                <Col xs={8} md={4} className="input-label">
                                    <Form.Control type="text" value={props.item.fitterName} readOnly />
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col xs={4} md={2} className="input-label">
                                    <Form.Label className="text-start">Location</Form.Label>
                                </Col>
                                <Col xs={8} md={4} className="input-label">
                                    <Form.Control type="text" value={props.item.location} readOnly />
                                </Col>

                                <Col xs={4} md={2} className="input-label">
                                    <Form.Label className="text-start">Location Code</Form.Label>
                                </Col>
                                <Col xs={8} md={4} className="input-label">
                                    <Form.Control type="text" value={props.item.locationCode} readOnly />
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col xs={4} md={1} className="input-label">
                                    <Form.Label className="text-start">Due Date</Form.Label>
                                </Col>
                                <Col xs={8} md={3} className="input-label">
                                    <Form.Control type="text" value={new Date(props.item.dueDate).toLocaleDateString('en-GB')} readOnly />
                                </Col>

                                <Col xs={4} md={1} className="input-label">
                                    <Form.Label className="text-start">Payment Terms</Form.Label>
                                </Col>
                                <Col xs={8} md={3} className="input-label">
                                    <Form.Control type="text" value={props.item.paymentTerms} readOnly />
                                </Col>

                                <Col xs={4} md={1} className="input-label">
                                    <Form.Label className="text-start">Currency</Form.Label>
                                </Col>
                                <Col xs={8} md={3} className="input-label">
                                    <Form.Control type="text" value={props.item.currencyCode} readOnly />
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col xs={4} md={1} className="input-label">
                                    <Form.Label className="text-start">Vehicle Reg</Form.Label>
                                </Col>
                                <Col xs={8} md={3} className="input-label">
                                    <Form.Control type="text" value={props.item.vehicleRegistrationNumber} readOnly />
                                </Col>

                                <Col xs={4} md={1} className="input-label numeric-input">
                                    <Form.Label className="text-start">Mileage</Form.Label>
                                </Col>
                                <Col xs={8} md={3} className="input-label">
                                    <Form.Control type="text" value={props.item.mileage} readOnly />
                                </Col>

                                <Col xs={4} md={1} className="input-label">
                                    <Form.Label className="text-start">ReTorque</Form.Label>
                                </Col>
                                <Col xs={8} md={3} className="input-label">
                                    <Form.Control type="text" value={props.item.reTorque} readOnly />
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col xs={4} md={1} className="input-label">
                                    <Form.Label className="text-start">Subtotal</Form.Label>
                                </Col>
                                <Col xs={8} md={3} className="input-label numeric-input">
                                    <Form.Control type="text"
                                        value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(props.item.amountExclVAT)}
                                        readOnly />
                                </Col>

                                <Col xs={4} md={1} className="input-label">
                                    <Form.Label className="text-start">VAT</Form.Label>
                                </Col>
                                <Col xs={8} md={3} className="input-label numeric-input">
                                    <Form.Control type="text"
                                        value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(props.item.vatAmount)}
                                        readOnly />
                                </Col>

                                <Col xs={4} md={1} className="input-label">
                                    <Form.Label className="text-start">total</Form.Label>
                                </Col>
                                <Col xs={8} md={3} className="input-label numeric-input">
                                    <Form.Control type="text"
                                        value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(props.item.amountInclVAT)}
                                        readOnly />
                                </Col>
                            </Row>

                        </Form>
                        <Row className="panel mt-3">
                            {
                                //!LoadingData ?

                                <ReactDataGrid
                                    idProperty="id"
                                    style={gridStyle}
                                    activateRowOnFocus={activateRowOnFocus}
                                    columns={columns}
                                    dataSource={rows}
                                    enableSelection={true}
                                    showHoverRows={true}
                                />
                                // : <Spinner className="mt-4" animation="binvoice" variant="primary" />
                            }
                        </Row>
                    </Col>
                    <Col xs={0} md={1}></Col>

                </Row>
            </Container>
        </>
    );
};
