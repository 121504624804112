import http from 'axios';
import download from 'downloadjs';

const InvoicesService = () => {
    const getInvoices = async (page, pageSize) => {
        let url = process.env.REACT_APP_API_URL + '/shop/invoices/?';// + 'code='+process.env.REACT_APP_FUNCTION_KEY;

        if(page) {
            url += `&page=${page}&`;
        }
        if(pageSize) {
            url += `pageSize=${pageSize}&`;
        }
        
        try {
            const res = await http.get(url);
            return res.data;
        } catch (err) {
            console.log(err);
        }
    }

    // 
    const getInvoicePdf = async (invoiceId) => {
        const url = `${process.env.REACT_APP_API_URL}/shop/invoices/${invoiceId}/pdfDocument/pdfDocumentContent`;// + '?code='+process.env.REACT_APP_FUNCTION_KEY;

        try {
            const res = await http.get(url);
            const base64Response = res.data;
            const binary = atob(base64Response);
            const binaryData = [];
    
            for (let i = 0; i < binary.length; i++) {
                binaryData.push(binary.charCodeAt(i));
            }
    
            const blob = new Blob([new Uint8Array(binaryData)], { type: 'application/pdf' });
            download(blob, `invoice_${invoiceId}.pdf`);
        } catch (err) {
            console.log(err);
        }
    }

    const getPinkSheetSalesOrderName = async (orderId) => {
        let url = process.env.REACT_APP_API_URL + `/pinkSheetSalesOrder/${orderId}`;

        try {
            const res = await http.get(url);
            return res.data;
        } catch (err) {
            console.log(err);
        }
    }

    return { getInvoices, getInvoicePdf, getPinkSheetSalesOrderName };

}

export default InvoicesService;