import React from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import axios from 'axios';
import { protectedResources } from '../../services/authConfig';


function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export const RequestInterceptor = (props) => {
  const { instance, accounts } = useMsal();

  const account = useAccount(accounts[0]);

  /* eslint-disable no-param-reassign */
  axios.interceptors.request.use(async (config) => {
    if (!account) {
      throw Error('No active account! Verify a user has been signed in.');
    }

    const response = await instance.acquireTokenSilent({
      ...protectedResources,
      account,
    });

    const bearer = `Bearer ${response.accessToken}`;
    config.headers.Authorization = bearer;
    config.headers['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_APIM_SUB_KEY;

    //if (process.env.NODE_ENV !== 'production') {
    //  const token = parseJwt(response.accessToken);
      // config.headers['customerid'] = token.oid;
      // config.headers['customername'] = token.name;
    //}

    const url = new URL(config.url);
    url.searchParams.set('code', process.env.REACT_APP_FUNCTION_KEY);

    //console.log("URL",url.toString());

    return config;
  });
  /* eslint-enable no-param-reassign */

  return (
    <>
      {props.children}
    </>
  );
};