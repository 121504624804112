import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping, faBoxesStacked } from '@fortawesome/free-solid-svg-icons'
import ProductsService from "../../services/productsService";
import { ShoppingCartService } from "../../services/shoppingCartService";
import { Card, Col, Row, Button } from "react-bootstrap";

export const ShopItem = (props) => {
    const service = ProductsService();
    const shoppingService = ShoppingCartService();
    const [itemImage, setItemImage] = useState(null);
    const [state, setState] = useState({
        quantity: 1,
        isValid: true,
        stock: "1"
    });

    useEffect(() => {
        if (props.itemImage) {
            console.log("props.itemImage",props.itemImage);
            setItemImage(props.itemImage);
        }else{
            service.getProductImages(props.itemId).then(image => {
                setItemImage(image);
            });
        }
        const newStock = props.itemAvailability > 20? ">20" : props.itemAvailability;
        let a = Object.assign({},state,{stock: newStock});
        setState(a);
    } , [props]);

    const validateNumberField = (quantity) => {
        return !(quantity.length == 0 || quantity < 1 || quantity > props.itemAvailability);
    }

    const changeQuantity = (e) => {
        const { value } = e.target;
        const isValid = validateNumberField(value);
        setState({
            quantity: value,
            isValid
        });
    }

    const toCamelCase = (str) => {
        const a = str.split(' ').map((item, index) => {
            return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
        });
       
        let result = a.join(' ');
        if (result.trim() === ""){
            result = "<No Brand>";
        }

        return result;
    };

    const onClickHandler = () => {
        console.log("onClickHandler",props.itemId, state.quantity);
        ShoppingCartService().addItem(props.itemId, state.quantity);
    };

    return (
        <Card className="pt-3 px-3 pb-2 shadow-sm rounded-10 mb-2 product-card" key={props.itemId}>
                <Card.Title className="product-card-title text-start"><h6>{toCamelCase(props.itemDescription)}</h6></Card.Title>
                <Card.Body className="p-0">
                    <Row>
                        <Col className="text-end text-muted">
                            <strong>{toCamelCase(props.itemBrand)}</strong>
                        </Col>
                    </Row>
                    <Row className="product-card-image-row">
                        <Col md={12} className="product-card-img-wrap">
                            <Card.Img className="mb-2 p-2 product-card-img" variant="top" src={`data:image/jpeg;base64,${itemImage}`} alt={props.itemName} />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col className="text-start">
                            <span>€ {props.itemPrice}</span>
                        </Col>
                        <Col md={5} className="text-end">
                            <FontAwesomeIcon icon={faBoxesStacked} /> {state.stock}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="input-group">
                            <input type="number" onChange={changeQuantity} min={1} max={props.itemAvailability} defaultValue={1} className="form-control shadow-none" placeholder="" aria-label="Example text with button addon" aria-describedby="add-to-basket-btn"></input>
                            <Button variant="primary" disabled={!state.isValid} id="add-to-basket-btn" onClick={onClickHandler}>
                                <FontAwesomeIcon icon={faBasketShopping} /><small className="m-1">ADD TO BASKET</small>
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
        </Card>
    );
};