import React from "react";
import { Col, Row } from "react-bootstrap";
import { ShopItem } from "./ShopItem";

/**
 * Renders items in card format
 * @param props 
 */
export const ShopItems = (props) => {
    return (
        <Row id="shop-items" className="g-3">
            
            {props.products.result.map((it, index) => (
                <Col key={index} sm={12} md={6} xxl={3} lg={4}>
                     <ShopItem 
                        key={it.systemId} 
                        itemName={it.no} 
                        itemImage={it.image} 
                        itemDescription={it.description} 
                        itemPrice={it.unitPrice} 
                        itemId={it.no} 
                        itemCategory={it.itemCategoryCode}
                        itemRadius={it.radius}
                        itemWall={it.wall}
                        itemDiameter={it.diameter}
                        itemBrand={it.brand}
                        itemAvailability={it.availableInventory}
                        />
                </Col>
             ))}
        </Row>
    );
};