import React from "react";
import { Form, Col, Row, Container, Spinner, ButtonGroup } from "react-bootstrap";
import '../../styles/ProfileData.css';

export const ProfileData = (props) => {

    
    return (
        <>
            <Container fluid>

                <Row >

                    <Col xs={0} md={1}></Col>
                    <Col xs={12} md={10}>

                        <Row className="pushtop-20">
                            <Col xs={4} md={1}>
                                
                            </Col>
                            <Col xs={4} md={9}>
                               <h4 className="text-center">Profile</h4>
                            </Col>
                            <Col xs={4} md={2}>
                                
                            </Col>

                        </Row>
                        <Form className="panel">
                            <Row className="mb-3 mt-3">
                                {/* <Col md={1}></Col> */}
                                <Col xs={6} md={2} className="input-label">
                                    <Form.Label className="text-start">Name:</Form.Label>
                                </Col>
                                <Col xs={6} md={4} className="input-label">
                                    <Form.Control type="text" value={props.graphData.givenName + " " + props.graphData.surname} readOnly />
                                </Col>
                                <Col xs={6} md={2} className="input-label">
                                    <Form.Label className="text-start">Email:</Form.Label>
                                </Col>
                                <Col xs={6} md={4} className="input-label">
                                    <Form.Control type="text" value={props.graphData.userPrincipalName} readOnly />
                                </Col>
                                <Col md={2}>

                                </Col>

                            </Row>
                            <Row className="mb-3">
                                {/* <Col xs={1}></Col> */}
                                <Col xs={6} md={2} className="input-label">
                                    <Form.Label className="text-start">Customer No.:</Form.Label>
                                </Col>
                                <Col xs={6} md={4} className="input-label">
                                    <Form.Control type="text" value={props.customerData.no} readOnly />
                                </Col>
                                <Col xs={6} md={2} className="input-label">
                                    <Form.Label className="text-start">Customer Name:</Form.Label>
                                </Col>
                                <Col xs={6} md={4} className="input-label">
                                    <Form.Control type="text" value={props.customerData.name} readOnly />
                                </Col>
                                <Col xs={2}>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                {/* <Col xs={1}></Col> */}
                                <Col xs={6} md={2} className="input-label">
                                    <Form.Label className="text-start">Balance :</Form.Label>
                                </Col>
                                <Col xs={6} md={4} className="input-label numeric-input">
                                    <Form.Control type="text" value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(props.customerData.balanceLCY)} readOnly />
                                </Col>
                                <Col xs={6} md={2} className="input-label">
                                    <Form.Label className="text-start">Balance Due :</Form.Label>
                                </Col>
                                <Col xs={6} md={4} className="input-label numeric-input">
                                    <Form.Control type="text" value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(props.customerData.balanceDueLCY)} readOnly />
                                </Col>
                                <Col xs={2}>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                {/* <Col xs={1}></Col> */}
                                <Col xs={6} md={2} className="input-label">
                                    <Form.Label className="text-start">Credit Limit :</Form.Label>
                                </Col>
                                <Col xs={6} md={4} className="input-label numeric-input">
                                    <Form.Control type="text" value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(props.customerData.creditLimitLCY)} readOnly />

                                </Col>

                                <Col xs={6} md={2} className="input-label">
                                    <Form.Label className="text-start">Outstanding Orders :</Form.Label>
                                </Col>
                                <Col xs={6} md={4} className="input-label numeric-input">
                                    <Form.Control type="text" value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(props.customerData.outstandingOrdersLCY)} readOnly />
                                </Col>
                                <Col xs={2}>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                {/* <Col xs={1}></Col> */}
                                <Col xs={6} md={2} className="input-label">
                                    <Form.Label className="text-start">Remaining Credit Limit :</Form.Label>
                                </Col>
                                <Col xs={6} md={4} className="input-label numeric-input">
                                    <Form.Control type="text" value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(props.customerData.remainingCreditLimitLCY)} readOnly />
                                </Col>
                                <Col xs={2}>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col xs={0} md={1}></Col>
                </Row>
            </Container>
            </>
            );
};