import React, { useEffect, useState } from "react";
import { Container, Row, Spinner, ButtonGroup, ToggleButton } from "react-bootstrap";
import { ShopCenterPagination } from "../shop/ShopCenterPagination";
import { PendingOrdersList } from "./PendingOrdersList";

import '../../styles/Orders.scss';

import PendingOrdersService from "../../services/pendingOrdersService";
import { Header } from "../common/Header";
import { TopBar } from "../common/TopBar";



/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const PendingOrders = (props) => {

    let pendingOrdersBase = {
        result: [],
        pages: [],
        totalPages: 0,
        totalCount: 0,
        page: 0,
        pageSize: 30
    };



    const orderService = PendingOrdersService();
    const [orders, setOrders] = useState(pendingOrdersBase);
    const [orderLoaded, setOrderLoaded] = useState(false);
    const [filter, setFilter] = useState({
        page: pendingOrdersBase.page,
        pageSize: pendingOrdersBase.pageSize,
    });

    const [totPages, setTotPages] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(30);

    const [showItem, setShowItem] = useState(false);

    useEffect(() => {

        const initState = async () => {
            setOrderLoaded(false);
            const allOrders = await orderService.getPendingOrders(0, 30);
            setOrders(allOrders);
            pendingOrdersBase = allOrders;
            setTotPages(allOrders.totalPages);
            setPage(allOrders.page);
            setPageSize(allOrders.pageSize);
            setFilter({
                totalCount: allOrders.totalCount,
                totalPages: allOrders.totalPages,
                page: allOrders.page,
                pageSize: allOrders.pageSize,
                pages: allOrders.pages,
            });
            setOrderLoaded(true);
        };
        initState();
    }, []);

    const updateOrders = async (a) => {
        setOrderLoaded(false);
        const page = a - 1;
        console.log("updateOrders", a, page);
        const newOrders = await orderService.getPendingOrders(page, pageSize)
        console.log("newOrders", newOrders);
        setOrders(newOrders);
        pendingOrdersBase = newOrders;
        setTotPages(newOrders.totalPages);
        setPage(newOrders.page);
        setPageSize(newOrders.pageSize);
        setFilter({
            totalCount: orders.totalCount,
            totPages: orders.totalPages,
            page: orders.page,
            pageSize: orders.pageSize,
        });

        setOrderLoaded(true);
        setShowItem(false);
    }

    const onPageChange = (newPage) => {
        if (page != newPage) {
            console.log("onPageChange", newPage, page);
            console.log("showPending", showPending);
            setPage(newPage);

            updateOrders(newPage);
            setShowItem(false);
            setOrderLoaded(true);
        }
    };

    return (
        <>
            <Header />
            <TopBar />
            <div id="shop-div" className="container-fluid">
                {
                    !showItem ?
                        <div className="row space text-center">
                            <h4>Pending Orders</h4>
                        </div>
                        : null
                }
                <div className="row space">
                    <>
                        {
                            orderLoaded ?
                                <>
                                    <Container>
                                        <Row>
                                            {
                                                (!showItem) ?

                                                    <ShopCenterPagination totPages={totPages}
                                                        currentPage={page}
                                                        pageClicked={(ele) => {
                                                            onPageChange(ele);
                                                        }} />
                                                    : !showItem ?
                                                        <div className="text-center"><Spinner className="mt-4 text-center spinner" animation="border" variant="primary" /></div>
                                                        : null
                                            }
                                        </Row>
                                    </Container>

                                    {
                                        (orders.totalCount > 0) ?

                                            <PendingOrdersList orders={orders}
                                                filter={filter}
                                                onPageChange={onPageChange}
                                                showItem={showItem}
                                                setShowItem={setShowItem}
                                            />
                                            : <div className="row space text-center">
                                                <h6>No pending orders found...</h6>
                                            </div>
                                    }
                                </>
                                : <div className="row space text-center">
                                    <h6>Loading...</h6>
                                </div>


                        }
                    </>
                </div>
            </div>
        </>
    );
};