import React from "react";
import "../../styles/Footer.scss";
import { useState, useEffect } from "react";
import VersionService from "../../services/versionService";

export const Footer = () => {

    const versionService = VersionService();
    const [version, setVersion] = useState("1.0");

    useEffect(() => {
        const fetchVersion = async () => {
            const version = await versionService.getVersion();
            setVersion(version);
        };
        fetchVersion();
    }
    , []);
  return (
    <div className="footer">
      Made by <a href="mailto:victor.zaragoza@kovimatic.com">Kovimatic</a> 2023, v. 1.7/{version}
    </div>
  );
};