import React, { useState, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import ProductsService from "../../services/productsService";

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css'
import '@inovua/reactdatagrid-community/theme/green-light.css'
import { ShoppingCartService } from "../../services/shoppingCartService";

const gridStyle = { minHeight: 550 }

export const ShopItemsList = (props) => {

    const navigate = useNavigate();
    const service = ProductsService();

    const [activateRowOnFocus, setActivateRowOnFocus] = useState(true);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [error, setError] = useState(null);
    const [quantity, setQuantity] = useState(1);

    const onChangeQuantity = (e) => {
        const { value } = e.target;
        console.log("onChangeQuantity-value", value);
        setQuantity(value);
        console.log("onChangeQuantity-quantity", quantity);
    }

    const rows = props.products.result.map((it, index) => {
      return {
        id: index,
        brand: it.brand,
        itemCategoryCode: it.itemCategoryCode,
        no: it.no,
        description: it.description,
        availableInventory: it.availableInventory,
        unitPrice: it.unitPrice,
        image: it.image,
      };
    });
  
    const columns = [
      {
        name: 'id',
        header: 'ID',
        defaultVisible: true,
        defaultWidth: 1,
        type: 'number',
      },
      {
        name: 'description',
  header: 'Description',
  defaultFlex: 1,
  render: ({ value, data }) => {
    const encodedData = encodeURIComponent(data.no);
    return (
      <Link
        style={{ textDecoration: 'underline', cursor: 'pointer' }}
        to={`/item/${encodedData}`
      }
      >
        {value}
      </Link>
    );
  },
      },
      {
        name: 'availableInventory',
        header: 'Stock',
        defaultFlex: 0.25,
        render: ({ value }) => <div style={{ textAlign: 'right' }}>{value > 20? ">20" : value}</div>,
      },
      {
        name: 'unitPrice',
        header: 'Price',
        defaultFlex: 0.25,
        render: ({ value }) => <div style={{ textAlign: 'right' }}>{value}</div>,
      },
      {
        name: 'quantity',
        header: 'Quantity',
        defaultFlex: 0.25,
        dataIndex: 'quantity',
        render: ({ data }) => (
          <div style={{ textAlign: 'right' }}>
            <input type="number" defaultValue={1} className="inputInGrid" onChange={onChangeQuantity}/>
            <Button
              variant="primary"
              size="sm"
              startIcon={<FontAwesomeIcon icon={faCartPlus} />}
              onClick={() => onBuyHandler(data)}
            ></Button>
          </div>
        ),
      },
    ];
  
    const shoppingCartService = ShoppingCartService();

    const onBuyHandler = async (item) => {
      console.log('onBuyHandler', item.no, quantity);
    
      try {
        shoppingCartService.addItem(item.no, quantity);
        const cart = await shoppingCartService.getShoppingCart();
        if (cart && cart.items) {
            console.log(cart.items.length);          
             props.updateCartItemCount();
        }
        setShowSuccessDialog(true);
      } catch (error) {
        console.error('Error adding item to cart:', error);
        setError(error);
        setShowErrorDialog(true);
      }
    };
  
    const handleCloseSuccessDialog = () => {
      setShowSuccessDialog(false);
    };
  
    const handleCloseErrorDialog = () => {
      setShowErrorDialog(false);
    };

    const renderRowContextMenu = (menuProps, { rowProps, cellProps }) => {
        menuProps.autoDismiss = true
        menuProps.items = [
        ]
    }

    return (
      <Row id="shop-items" className="g-3">
        <Box sx={{ height: '100%', width: '100%' }}>
          <ReactDataGrid
              idProperty="id"
              style={gridStyle}
              activateRowOnFocus={activateRowOnFocus}
              columns={columns}
              dataSource={rows}
              enableSelection={true}
              renderRowContextMenu={renderRowContextMenu}
              showHoverRows={true}
            />
          
          <Dialog open={showSuccessDialog} onClose={handleCloseSuccessDialog}>
            <DialogTitle>Success</DialogTitle>
            <DialogContent>
              <DialogContentText>The item has been added to the cart.</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseSuccessDialog}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={showErrorDialog} onClose={handleCloseErrorDialog}>
            <DialogTitle>Error</DialogTitle>
            <DialogContent>
              <DialogContentText>There's been an error adding the item to the cart.<br/>
              {error && error.message}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseErrorDialog}>OK</Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Row>
    );
  };