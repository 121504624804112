import http from 'axios';

const CustomerService = () => {
    const getCustomer = async () => {
        const url = process.env.REACT_APP_API_URL + '/shop/customer';// + '?code='+process.env.REACT_APP_FUNCTION_KEY;

        try {
            const res = await http.get(url, {
                headers: {
                    'Content-Type': 'application/json',
            }}
                );
            return res.data;
        } catch (err) {
            console.log(err);
        }
    }

    return { getCustomer }
}

export default CustomerService;
