import http from 'axios';

const OrdersService = () => {
    const getOrders = async (page, pageSize) => {
        let url = process.env.REACT_APP_API_URL + '/shop/order?';// + 'code='+process.env.REACT_APP_FUNCTION_KEY;

        if (page) {
            url += `&page=${page}&`;
        }
        if (pageSize) {
            url += `pageSize=${pageSize}&`;
        }

        try {
            const res = await http.get(url);
            return res.data;
        } catch (err) {
            console.log(err);
        }
    }

    const getOrderLines = async (orderId) => {
        let url = process.env.REACT_APP_API_URL + `/shop/order/${orderId}/lines`;// + '?code='+process.env.REACT_APP_FUNCTION_KEY;

        try {
            const res = await http.get(url);
            return res.data;
        } catch (err) {
            console.log(err);
        }
    }



    const getPendingOrderLines = async (orderId) => {
        let url = process.env.REACT_APP_API_URL + `/shop/pendingOrders/${orderId}/lines`;// + '?code='+process.env.REACT_APP_FUNCTION_KEY;

        try {
            const res = await http.get(url);
            return res.data;
        } catch (err) {
            console.log(err);
        }
    }


    const printPinkSheet = async (url, file) => {
        try {
            console.log("try to download file: ", url, file);
            window.open(url, '_blank');
        } catch (err) {
            console.log(err);
        }
    }

    const printPinkSheets = async(files) => {
        try {
            let urls = [];
    
            for (let i = 0; i < files.length; i++) {
                let shipmentUrl = `https://fitterportalstorage.blob.core.windows.net/fitterapp-pdf-shipments/${files[i]}`;
                urls.push(shipmentUrl);
            }
    
            return urls;
        } catch (err) {
            console.log(err);
        }
    }

    const getPinkSheetShipmentName = async (orderId, items ) => {
        let encodedItems = btoa(items);
        let url = process.env.REACT_APP_API_URL + `/shop/order/${orderId}/shipment/${encodedItems}`;

        try {
            const res = await http.get(url);
            return res.data;
        } catch (err) {
            console.log(err);
        }
    }

    return { getOrders, getOrderLines, getPendingOrderLines, printPinkSheet, printPinkSheets, getPinkSheetShipmentName };

}

export default OrdersService;