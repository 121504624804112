import http from 'axios';
import base64 from 'base-64';

const ProductsService = () => {
  const getProducts = async (search, filters) => {
    let url = process.env.REACT_APP_API_URL_POST + '/shop/products?' + 'code=' + process.env.REACT_APP_FUNCTION_KEY;

    if (filters.page) {
      url += `&page=${filters.page}&`;
    }
    if (filters.pageSize) {
      url += `pageSize=${filters.pageSize}&`;
    }

    try {
      const res = await http.post(url, { search, filters });

      // const cacheName = 'products';
      // const cache = await caches.open(cacheName);
      // res.data.result.forEach(async (item) => {
      //   const response = new Response(JSON.stringify(item), {
      //     headers: { 'Content-Type': 'application/json' }
      //   });
      //   await cache.put(`product_${item.no}`, response.clone());
      // });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }


  const getProductImages = async (id) => {
    const cacheName = 'product-images';
    const cache = await caches.open(cacheName);
    const cachedResponse = await cache.match(`productImage_${id}`);
    if (cachedResponse) {
      return cachedResponse.text();
    }

    const url = process.env.REACT_APP_API_URL + `/shop/products/${base64.encode(id)}/images`;// + '?code='+process.env.REACT_APP_FUNCTION_KEY;

    try {
      const res = await http.get(url);
      const pictureBase64 = res.data.pictureBase64;
      const response = new Response(pictureBase64, {
        headers: { 'Content-Type': 'text/plain' }
      });
      await cache.put(`productImage_${id}`, response.clone());
      return pictureBase64;
    } catch (err) {
      console.error(err);
      return '';
    }
  };

  const getProductFilters = async () => {
    const url = process.env.REACT_APP_API_URL + '/shop/products/filters';// + '?code='+process.env.REACT_APP_FUNCTION_KEY;

    try {
      const res = await http.get(url);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }

  const getProduct = async (id) => {
    const url = process.env.REACT_APP_API_URL + `/shop/products/${base64.encode(id)}`;// + '?code='+process.env.REACT_APP_FUNCTION_KEY;

    try {
      const res = await http.get(url);
      return res.data[0];
    } catch (err) {
      console.log(err);
    }
  }


  return { getProducts, getProductImages, getProductFilters, getProduct };

}

export default ProductsService;