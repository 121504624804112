import React from 'react';

const ProfileTop = ({ userName, customerName, balanceLCY, balanceDueLCY, creditLimitLCY, outstandingOrdersLCY }) => {
    return (
        <div className="profile-top profile-bar mb-4">
            <span>User Name: <strong>{userName}</strong></span> ||
            <span>  Customer Name: <strong>{customerName}</strong></span>  ||
            <span>  Balance LCY: <strong>{balanceLCY}</strong></span>  ||
            <span>  Balance Due LCY: <strong>{balanceDueLCY}</strong></span>  ||
            <span>  Credit Limit LCY: <strong>{creditLimitLCY}</strong></span> ||
            <span>  Outstanding Orders LCY: <strong>{outstandingOrdersLCY}</strong></span>
        </div>
    );
};

export default ProfileTop;
