import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";
import { faBasketShopping, faBoxesStacked } from '@fortawesome/free-solid-svg-icons';
import { TopBar } from "../common/TopBar";
import ProductsService from "../../services/productsService";
import { Card, Col, Row, Button, Container, ButtonGroup, Spinner } from "react-bootstrap";
import { ArrowReturnLeft } from 'react-bootstrap-icons';
import { Header } from "../common/Header";
import { ShoppingCartService } from "../../services/shoppingCartService";

export const ShopItemPage = (props) => {
    let { id } = useParams();

    const shoppingCartService = ShoppingCartService();

    const [item, setItem] = useState();
    const service = ProductsService();
    const [itemImage, setItemImage] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    const [itemLoaded, setItemLoaded] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [error, setError] = useState(null);

    const [state, setState] = useState({
        isValid: true,
        stock: "1"
    });

    const navigate = useNavigate();

    const handleCloseSuccessDialog = () => {
        setShowSuccessDialog(false);
        onBack();
    };

    const onBack = () => {
        navigate("/shop", { replace: true });
    };

    useEffect(() => {
        const encodedData = (id);
        service.getProduct(encodedData).then(stitem => {
        
        setItem(stitem);
    
        const newStock = stitem.inventory > 20 ? ">20" : stitem.inventory;
        let a = Object.assign({}, state, { stock: newStock });

        setState(a);
        
        setItemLoaded(true);
        
        service.getProductImages(stitem.no).then(image => {
        
            setItemImage(image);
            setImageLoaded(true);

        });

        
    });
}, [id]);

    const validateNumberField = (quantity) => {
        return !(quantity.length == 0 || quantity < 1 || quantity > item.availableInventory);
    }

    const changeQuantity = (e) => {
        const { value } = e.target;
        const isValid = validateNumberField(value);
        setState({
            isValid
        });
        quantity = value;
    }

    const toCamelCase = (str) => {
        if (str) {
            const a = str.split(' ').map((item, index) => {
                return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
            });

            let result = a.join(' ');
            if (result.trim() === "") {
                result = "<No Brand>";
            }

            return result;
        }
    };

    

    const onBuyHandler = async () => {
        console.log('onBuyHandler', item.no, quantity);
      
        try {
          await shoppingCartService.addItem(item.no, quantity);
          const cart = await shoppingCartService.getShoppingCart();
          if (cart && cart.items) {
              console.log(cart.items.length);          
               navigate("/basket", { replace: true });
          }
          setShowSuccessDialog(true);
        } catch (error) {
          console.error('Error adding item to cart:', error);
          setError(error);
          setShowErrorDialog(true);
        }
      };

    return (
        <>
        <Header />
        <TopBar />
            <Container className="fluid">
            <Row className="pushdown-10">
                            <Col xs={4} md={2}>
                                <ButtonGroup size="lg" className="mb-2">
                                    <button className="btn btn-secondary text-start" onClick={onBack}>
                                        <ArrowReturnLeft /> Back
                                    </button>
                                </ButtonGroup>
                            </Col>
                            <Col xs={4} md={8} className="text-center">
                                {
                                    !itemLoaded ?
                                    <div className="text-center">
                                        <Spinner className="mt-4" animation="border" variant="primary" />
                                    </div>
                                        : <h4>Product Page</h4>
                                }
                            </Col>
                            <Col xs={4} md={2} className="text-end">
                                <ButtonGroup size="lg" className="mb-2">
                                    {/* <button className="btn btn-primary text-start" onClick={printPinkSheet}>
                                        <Printer /> Pink Sheet
                                    </button> */}
                                </ButtonGroup>
                            </Col>

                        </Row>
                {itemLoaded && item &&
                <Row>
                <Card className="pt-3 px-3 pb-2 shadow-sm rounded-10 mb-2 product-card" key={item.no}>
                    <Card.Title className="product-card-title text-start">
                        <h2 className="text-center">{item.no}</h2>
                        <h6 className="text-center">{toCamelCase(item.description)}</h6>
                    </Card.Title>
                    <Card.Body className="p-0">
                        <Row>
                            <Col className="text-end text-muted">
                                <strong>{toCamelCase(item.brand)}</strong>
                            </Col>
                        </Row>
                        <Row className="product-card-image-row">
                            <Col md={12} className="product-card-img-wrap">
                                { imageLoaded ? <Card.Img className="mb-2 p-2 product-card-img" variant="top" src={`data:image/jpeg;base64,${itemImage}`} alt={item.no} /> : <Spinner className="mt-4" animation="border" variant="primary" />}
                                
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col className="text-start">
                                <h3>€ {item.unitPrice}</h3>
                            </Col>
                            <Col md={5} className="text-end">
                                <FontAwesomeIcon icon={faBoxesStacked} /> {state.stock}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={1} md={4}></Col>
                            <Col xs={4} md={2} className="">
                                <input type="number" onChange={changeQuantity}
                                    min={1} max={item.availableInventory}
                                    defaultValue={quantity}
                                    className="form-control shadow-none"></input>
                            </Col>
                            <Col xs={5} md={2}>
                                <Button variant="primary" className="btn btn-primary" disabled={!state.isValid} id="add-to-basket-btn" onClick={onBuyHandler}>
                                    <FontAwesomeIcon icon={faBasketShopping} />  BUY
                                </Button>
                            </Col>
                            <Col xs={2} md={4}></Col>
                        </Row>
                    </Card.Body>
                </Card>
                </Row>
                }
                <Dialog open={showSuccessDialog} onClose={handleCloseSuccessDialog}>
            <DialogTitle>Success</DialogTitle>
            <DialogContent>
              <DialogContentText>The item has been added to the cart.</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseSuccessDialog}>OK</Button>
            </DialogActions>
          </Dialog>
            </Container>
        </>
    );
};