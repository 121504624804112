import React, { useState, useEffect} from "react";
import { useMsal } from "@azure/msal-react";
import { ProfileData } from "./ProfileData";
import { callMsGraph } from "../../services/graph";
import { loginRequest } from "../../services/authConfig";
import CustomerService  from "../../services/customerService";
import { Header } from "../common/Header";
import { TopBar } from "../common/TopBar";

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
export const ProfileContent = () => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [customerData, setCustomerData] = useState(null);

    useEffect(() => {
        if (accounts.length > 0) {
            RequestProfileData();
        };
        const customerService = CustomerService();
        customerService.getCustomer().then((response) => {
            console.log(response);
            setCustomerData(response);
            console.log(customerData);
        });

    }, [accounts]);

    function RequestProfileData() {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            callMsGraph(response.accessToken).then(resp => setGraphData(resp));
        });
    }

    return (
        <>
        <Header />
            <TopBar />
           
            {graphData && customerData ?
                <ProfileData graphData={graphData} customerData={customerData}/>
                :
                ""
            }
        </>
    );
};