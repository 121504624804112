import http from 'axios';

const VersionService = () => {
    const getVersion = async () => {
        let url = process.env.REACT_APP_API_URL_POST + '/version' + '?code='+process.env.REACT_APP_FUNCTION_KEY;
        
        const httpOptions = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Ocp-Apim-Subscription-Key': process.env.REACT_APP_APIM_SUB_KEY
            }
        };
        try {
            const res = await http.post(url, httpOptions);
            return res.data;
        } catch (err) {
            console.log(err);
        }
    }

    return { getVersion };

}

export default VersionService;