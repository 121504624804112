import React, { useEffect, useState } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { Container, Row, Spinner, ButtonGroup, ToggleButton } from "react-bootstrap";
import { ShopCenterPagination } from "../shop/ShopCenterPagination";
import { OrdersList } from "./OrdersList";

import '../../styles/Orders.scss';

import OrdersService from "../../services/ordersService";
import { Header } from "../common/Header";
import { TopBar } from "../common/TopBar";



/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const Orders = (props) => {

    let ordersBase = {
        result: [],
        pages: [],
        totalPages: 0,
        totalCount: 0,
        page: 1,
        pageSize: 30
    };



    const orderService = OrdersService();
    const [orders, setOrders] = useState(ordersBase);
    const [orderLoaded, setOrderLoaded] = useState(false);
    const [filter, setFilter] = useState({
        page: ordersBase.page,
        pageSize: ordersBase.pageSize,
    });

    const [totPages, setTotPages] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(30);

    const [showPending, setShowPending] = useState(false);
    const [pendingOrders, setPendingOrders] = useState([]);

    const [showItem, setShowItem] = useState(false);

    useEffect(() => {

        const initState = async () => {
            setOrderLoaded(false);
            const allOrders = await orderService.getOrders(filter.page, filter.pageSize);
            setOrders(allOrders);
            ordersBase = allOrders;
            setTotPages(allOrders.totalPages);
            setPage(allOrders.page);
            setPageSize(allOrders.pageSize);
            setFilter({
                totalCount: allOrders.totalCount,
                totalPages: allOrders.totalPages,
                page: allOrders.page,
                pageSize: allOrders.pageSize,
                pages: allOrders.pages,
            });
            updateOrders(1);
        };
        initState();
    }, []);

    const updateOrders = async (a) => {
        setOrderLoaded(false);
        const newOrders = await orderService.getOrders(a, pageSize);

        setOrders(newOrders);
        ordersBase = newOrders;
        setTotPages(newOrders.totalPages);
        setPage(newOrders.page);
        setPageSize(newOrders.pageSize);
        setFilter({
            totalCount: ordersBase.totalCount,
            totPages: ordersBase.totalPages,
            page: ordersBase.page,
            pageSize: ordersBase.pageSize,
        });

        setOrderLoaded(true);
        setShowItem(false);
    }

    const onPageChange = (newPage) => {
        if (page != newPage) {
            console.log("onPageChange", newPage, page);
            console.log("showPending", showPending);
            setPage(newPage);

            updateOrders(newPage);
            setShowItem(false);
            setOrderLoaded(true);
        }
    };


    return (
        <>
            <Header />
            <TopBar />
            <div id="shop-div" className="container-fluid">
                {
                    !showItem ?
                            <div className="row space text-center">
                                <h4 className="">Posted Orders</h4>
                            </div>
                        : null
                }
                <div className="row space">
                    <>
                        <Container>
                            <Row>
                                {
                                    (orderLoaded & !showItem) ?

                                        <ShopCenterPagination totPages={totPages}
                                            currentPage={page}
                                            pageClicked={(ele) => {
                                                onPageChange(ele);
                                            }} />
                                        : !showItem ? 
                                        <div className="text-center"><Spinner className="mt-4 text-center spinner" animation="border" variant="primary" /></div>
                                            : null
                                }
                            </Row>
                        </Container>
                        {
                            orders.totalCount > 0 ?
                        
                        <OrdersList orders={orders}
                            filter={filter}
                            onPageChange={onPageChange}
                            showItem={showItem}
                            setShowItem={setShowItem}
                        />
                        : <div className="row space text-center">
                            <h6>No orders found...</h6>
                        </div>
                        }
                    </>

                </div>
            </div>
        </>
    );
};