import React, { useEffect, useState } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { Container, Row, Spinner } from "react-bootstrap";
import { ShopCenterPagination } from "../shop/ShopCenterPagination";
import { InvoicesList } from "./InvoicesList";

import '../../styles/Invoices.scss';

import InvoicesService from "../../services/invoicesService";
import { Header } from "../common/Header";
import { TopBar } from "../common/TopBar";


/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const Invoices = (props) => {

    let invoicesBase = {
        result: [],
        pages: [],
        totalPages: 0,
        totalCount: 0,
        page: 1,
        pageSize: 30
    };


    const orderService = InvoicesService();
    const [invoices, setInvoices] = useState(invoicesBase);
    const [orderLoaded, setInvoiceLoaded] = useState(false);
    const [filter, setFilter] = useState({
        page: invoicesBase.page,
        pageSize: invoicesBase.pageSize,
    });

    const [totPages, setTotPages] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(30);

    const [showItem, setShowItem] = useState(false);

    useEffect(() => {

        const initState = async () => {
            setInvoiceLoaded(false);
            const allInvoices = await orderService.getInvoices(filter.page, filter.pageSize);
            setInvoices(allInvoices);
            invoicesBase = allInvoices;
            setTotPages(allInvoices.totalPages);
            setPage(allInvoices.page);
            setPageSize(allInvoices.pageSize);
            setFilter({
                totalCount: allInvoices.totalCount,
                totalPages: allInvoices.totalPages,
                page: allInvoices.page,
                pageSize: allInvoices.pageSize,
                pages: allInvoices.pages,
            });


            setInvoiceLoaded(true);
            setShowItem(false);

        };
        initState();
    }, []);

    const updateInvoices = async (a) => {
        setInvoiceLoaded(false);

        setInvoices(await orderService.getInvoices(a, pageSize));

        setFilter({
            page: invoicesBase.page,
            pageSize: invoicesBase.pageSize,
        });

        setInvoiceLoaded(true);
        setShowItem(false);
    }

    const onPageChange = (newPage) => {
        if (page != newPage) {
            console.log("onPageChange", newPage, page);
            setPage(newPage);

            updateInvoices(newPage);
            setShowItem(false);
        }
    };



    return (
        <>
            <Header />
            <TopBar />
            <div id="shop-div" className="container-fluid">
                <div className="row space text-center">
                    <h4>Invoices</h4>
                </div>
                <div className="row space">
                    <>
                        <Container>
                            <Row>
                                {
                                    //orderLoaded &
                                    //(
                                        invoices.result.length > 0 ?
                                        (
                                            !showItem 
                                            ?
                                            <ShopCenterPagination totPages={totPages}
                                                currentPage={page}
                                                pageClicked={(ele) => {
                                                    onPageChange(ele);
                                                }} />
                                            : 
                                            null
                                        )
                                        : 
                                        <div className="row space text-center"><h6>No invoices found...</h6></div>
                                    //)
                                }
                                {/* {
                                    (orderLoaded & !showItem) ?

                                        <ShopCenterPagination totPages={totPages}
                                            currentPage={page}
                                            pageClicked={(ele) => {
                                                onPageChange(ele);
                                            }} />
                                        : !orderLoaded ? 
                                        
                                            : null
                                } */}
                            </Row>
                        </Container>

                        {
                            orderLoaded &
                            invoices.result.length > 0 ?  
                            <InvoicesList invoices={invoices}
                            filter={filter}
                            onPageChange={onPageChange}
                            showItem={showItem}
                            setShowItem={setShowItem}
                            /> 
                            : null
                        }
                        
                    </>

                </div>
            </div>
        </>
    );
};