import React, { useState, useEffect } from "react";
import { Footer } from "../common/Footer";


export const PageLayout = (props) => {



  return (
    <>
    {props.children}
    <Footer />
    </>
  );
};