import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Col, Dropdown, DropdownButton, Row, Form, Button, Container } from "react-bootstrap";

/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const ShopSort = (props) => {
    
    const [search, setSearch] = useState(props.search);

    const handleChange = (e) => {
        setSearch(e.target.value);
    };

    const executeSearch = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        props.onSearchChange(search);
    };

    const [dropDownShow, setDropDownShow] = useState("30");

    const sortOptions = [{
        name: "Name Asc",
        sort: "name",
        sortOrder: "asc"
    },
    {
        name: "Name Desc",
        sort: "name",
        sortOrder: "desc"
    },
    {
        name: "Price: High to Low",
        sort: "price",
        sortOrder: "desc"
    },
    {
        name: "Price: Low to High",
        sort: "price",
        sortOrder: "asc"
    },
    {
        name: "Stock: Max to Min",
        sort: "stock",
        sortOrder: "desc"
    },
    {
        name: "Stock: Min to Max",
        sort: "stock",
        sortOrder: "asc"
    }];

    const [dropDownSort, setDropDownSort] = useState("Name Asc");

    useEffect(() => {
        const debounceSearch = setTimeout(() => {
            executeSearch();
        }, 500);

        return () => {
            clearTimeout(debounceSearch);
        };
    }, [search]);

    useEffect(() => {
        props.onPageSizeChange(dropDownShow);
    }, [dropDownShow]);

    return (
        <Container fluid className="px-0"> 
            <Row>
            <Col className="align-self-end">
                    <Form className="row g-3 justify-content-center align-items-center" onSubmit={executeSearch}>
                        <Col className="">
                        <Form.Control className="" type="text" placeholder="Search products" value={search} onChange={handleChange} />
                        </Col>

                    </Form>
                </Col>

                <Col md="auto" className="noPadding pushdown-3">
                    <img src={props.togglesrc} className="toolsBarButtons toggleButton" onClick={props.onToggleViewChange} alt="View toggle" />
                </Col>
            </Row>
        </Container>
    );
};