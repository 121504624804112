import React, { useEffect, useState } from "react";
import { ShopSort } from "./ShopSort";
import { ShopItems } from "./ShopItems";
import { ShopItemsList } from "./ShopItemsList";
import { ShopCenterPagination } from "./ShopCenterPagination";
import { Form, Col, Row, Container, ButtonGroup, Spinner, Modal, Button } from "react-bootstrap";
import { Printer, ArrowReturnLeft } from 'react-bootstrap-icons';
import { CartCheck, CartX, Cart } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { ShopCart } from "./ShopCart";
export const ShopCenter = (props) => {

    const [ListView, setListView] = useState(false);
    const [showCart, setShowCart] = useState(false);

    const listViewIcon = "/assets/images/mts/toggleCards.webp";
    const cardsViewIcon = "/assets/images/mts/toggleList.webp";

    const navigate = useNavigate();

    useEffect(() => {
        const initState = async () => {
            props.setShowItem(false);
            setListView(true);
        }
        initState();
    }, []);

    const onToggleViewChange = () => {
        const state = !ListView;
        setListView(state);
        props.setShowItem(false);
    }

    const toggleShowCart = () => {
        setShowCart(!showCart);

    }

    return (

        <div id="shopCenter-div" className="col-md-12">
            <h3 className="mb-4 text-center">Shop</h3>

            {
                !props.showItem
                &&
                <>
                    <Container fluid>
                        <Row>
                            <Col xs={8} md={8}>
                                <ShopSort togglesrc={ListView ? listViewIcon : cardsViewIcon}
                                    onToggleViewChange={onToggleViewChange}
                                    onSearchChange={props.onSearchChange} search={props.search}
                                    onSortChange={props.onSortChange} onPageSizeChange={props.onPageSizeChange}
                                />
                            </Col>
                            <Col xs={4} md={4} className="text-end">
                                <Button className="btn btn-primary text-start" onClick={toggleShowCart}>
                                    <Cart /> {showCart ? 'Hide Basket' : 'See Basket'}
                                </Button>
                            </Col>
                        </Row>


                        <Row className="justify-content-between mb-4">

                            <Col xs={12} md={12}>
                                {
                                    props.loadingProducts ?

                                        <ShopCenterPagination totPages={props.pages.length}
                                            currentPage={props.currentPage}
                                            pageClicked={(ele) => {
                                                props.onPageChange(ele);
                                            }} />
                                        : <div className="text-center"><Spinner className="mt-4 text-center spinner" animation="border" variant="primary" /></div>
                                }

                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8} md={showCart ? 8 : 12}>
                                {!ListView &&
                                    <ShopItems updateCartItemCount={props.updateCartItemCount}
                                        products={props.products} />
                                }
                                {ListView &&
                                    <ShopItemsList updateCartItemCount={props.updateCartItemCount}
                                        products={props.products}
                                        showItem={props.showItem}
                                        setShowItem={props.setShowItem} />

                                }

                            </Col>
                            {showCart &&
                                <>

                                    <Col xs={4} md={4}>
                                        <ShopCart showCart={showCart} setShowCart={setShowCart} />
                                    </Col>
                                </>
                            }
                        </Row>
                    </Container>
                </>
            }

        </div>
    );
};